export const types = {
  SET_MATERIALS: 'SET_MATERIALS',
  SET_MATERIAL_IMAGE_LOADING: 'SET_MATERIAL_IMAGE_LOADING',
};

const actions = {
  setMaterials: (payload) => ({
    type: types.SET_MATERIALS,
    payload,
  }),
  setMaterialsImageLoading: (payload) => ({
    type: types.SET_MATERIAL_IMAGE_LOADING,
    payload,
  }),
};

export default actions;
