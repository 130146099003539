import actions from './actions';
import toasterActions from 'components/toaster/container/actions';
import { getDiscounts, createDiscount, editDiscount } from './api';
import { getMaterials } from 'containers/materials/container/api';

const discountsPerformances = (dispatch) => {
  const getDiscountsContext = async ({ pageNumber, discounts = [] }) => {
    try {
      const result = await getDiscounts({ pageNumber, discounts });
      if (result) {
        dispatch(actions.setDiscounts(result.discounts));
        dispatch(actions.setTotalDiscounts(result.total));
      }
    } catch (e) {
      console.error(e);
    }
  };
  const createDiscountContext = async (data) => {
    try {
      const result = await createDiscount(data);
      if (result) {
        await getDiscountsContext({ pageNumber: 1, discounts: [] });
        dispatch(toasterActions.updateToaster({ type: 'success', message: 'Discount created' }));
      }
    } catch (e) {
      dispatch(toasterActions.updateToaster({ type: 'error', message: 'Discount not created' }));
      console.error(e);
    }
  };
  const editDiscountContext = async (data, id) => {
    try {
      const result = await editDiscount(data, id);
      if (result) {
        await getDiscountsContext({ pageNumber: 1, discounts: [] });
        dispatch(toasterActions.updateToaster({ type: 'success', message: 'Discount edited' }));
      }
    } catch (e) {
      dispatch(toasterActions.updateToaster({ type: 'error', message: 'Discount not edited' }));
      console.error(e);
    }
  };
  const setDiscountsContext = async (data) => {
    try {
      dispatch(actions.setDiscounts(data));
    } catch (e) {
      console.error(e);
    }
  };
  const getMaterialsContext = async () => {
    try {
      const result = await getMaterials();
      if (result) {
        dispatch(actions.setMaterials(result.materials));
      }
    } catch (e) {
      console.error(e);
    }
  };
  return {
    getDiscountsContext,
    setDiscountsContext,
    getMaterialsContext,
    createDiscountContext,
    editDiscountContext,
  };
};

export default discountsPerformances;
