import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext } from 'context/themeContext';
import Button from 'components/button';

const Wrapper = styled.div`
  position: absolute;
  bottom: 4.424vw;
  left: 0;
  width: 100%;
  height: 2.342vw;
  opacity: ${(props) => (props.display ? '1' : '0')};
  visibility: ${(props) => (props.display ? 'visible' : 'hidden')};
  transition: 200ms ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StepsWrraper = styled.div`
  position: relative;
  width: 13.742vw;
  margin-right: 1.56vw;
  display: flex;
  justify-content: space-between;
`;

const Circle = styled.div`
  position: relative;
  width: 1.613vw;
  height: 1.613vw;
  border-radius: 50%;
  background-color: ${(props) => props.theme.inputBackground};
`;

const DoneSteps = styled.div`
  position: absolute;
  width: ${(props) => (props.boardingState - 1) * 3.071 + 1.613 + 'vw'};
  height: 1.613vw;
  border-radius: 1.613vw;
  background-color: ${(props) => props.theme.blueColorSecondary};
  transition: width 200ms ease-in-out;
`;

const Steps = (props) => {
  const { theme } = useContext(ThemeContext);
  const { display, boardingState, onNextClick, deActive } = props;

  return (
    <Wrapper display={display}>
      <StepsWrraper>
        <Circle theme={theme} />
        <Circle theme={theme} />
        <Circle theme={theme} />
        <Circle theme={theme} />
        <Circle theme={theme} />
        <DoneSteps theme={theme} boardingState={boardingState} />
      </StepsWrraper>
      <Button onClick={onNextClick} text="Next" deActive={deActive} />
    </Wrapper>
  );
};

export default Steps;
