import actions from './actions';
import toasterActions from 'components/toaster/container/actions';
import {
  getFiles,
  postFile,
  getVolumePrices,
  getTimePrices,
  getMaterials,
  getQualityPrice,
} from './api';

const slicerPerformances = (dispatch,state,ceiling) => {
  const setSelectedColorObject = async (value, slicerNumber) => {
    dispatch(actions.setSelectedColorObject({ value, slicerNumber }));
  };
  const setSlicerDetailVolume = async (value, slicerNumber) => {
    dispatch(actions.setSlicerDetailVolume({ value, slicerNumber }));
  };

  const setSlicerDetailBoundingbox = async (value, slicerNumber) => {
    dispatch(actions.setSlicerDetailBoundingbox({ value, slicerNumber }));
  };

  const setSlicerDetailDensity = async (value, slicerNumber) => {
    dispatch(actions.setSlicerDetailDensity({ value, slicerNumber }));
  };

  
  const setQualityDetailTime = async (value, slicerNumber,qualityId) => {
    dispatch(actions.setQualityDetailTime({ value, slicerNumber,qualityId }));
  };

  

  const setFiles = async (value) => {
    dispatch(actions.setFiles(value));
  };

  const getFilesContext = async (data) => {
    try {
      const result = await getFiles(data);
      if (result) {
        dispatch(actions.setFiles(result));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const postFileContext = async ({ data, files, slicerNumber, userId }) => {
    dispatch(actions.setPercentCompleted({ value: 0, slicerNumber }));
    let config = {
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        dispatch(actions.setPercentCompleted({ value: percentCompleted, slicerNumber }));
      },
    };
    try {
      const result = await postFile(data, config, userId);
      if (result) {
        
        dispatch(actions.setFiles([...files, result]));
        setScaleXInitial(result.scales.x, slicerNumber);
        setScaleX(result.scales.x, slicerNumber);
        setScaleY(result.scales.y, slicerNumber);
        setScaleZ(result.scales.z, slicerNumber);
        setSelectedStl(result.stl_path, slicerNumber);
        setSelectedStep(2, slicerNumber);
        setTimeout(() => {
          dispatch(actions.setPercentCompleted({ value: -1, slicerNumber }));
        }, 1000);
      }
    } catch (e) {
      if(e.response.status===500){
        dispatch(toasterActions.updateToaster({ type: 'error', message: 'Wrong file format' }));
      }
      if(e.response.status===403)
      dispatch(toasterActions.updateToaster({ type: 'error', message: 'This shop storage is full. Please contact the administrator.' }));
    }
  };

  const getVolumePricesContextByState = async ( slicerNumber) => {
    let file=state.selectedFiles[slicerNumber];
    const req={
      stl_path: file.selectedStl,
      x: file.scaleX,
      y: file.scaleY,
      z: file.scaleZ,
      ceiling,
    };
    try {
      dispatch(actions.setVolumePricesLoading({ value: true, slicerNumber }));
      const result = await getVolumePrices(req);
      if (result) {
        dispatch(actions.setVolumePrices({ value: result.materials, slicerNumber }));
        dispatch(
          actions.setSlicerDetailBoundingbox({ value: Number(result.bounding_box), slicerNumber })
        );
        dispatch(actions.setSlicerDetailVolume({ value: Number(result.volume), slicerNumber }));
        dispatch(actions.setVolumePricesLoading({ value: false, slicerNumber }));
        dispatch(actions.setScaledStlPath({ value: result.scaledStlPath, slicerNumber }));
      }
    } catch (e) {
      dispatch(actions.setVolumePrices({ value: [], slicerNumber }));
      dispatch(actions.setSlicerDetailBoundingbox({ value: 0, slicerNumber }));
      dispatch(actions.setSlicerDetailVolume({ value: 0, slicerNumber }));
      dispatch(actions.setVolumePricesLoading({ value: false, slicerNumber }));
      console.error(e);
    }
  };

  const getTimePricesContextByState = async ( slicerNumber) => {
    let file=state.selectedFiles[slicerNumber];
    const req={
      stl_path: file.selectedStl,
      x: file.scaleX,
      y: file.scaleY,
      z: file.scaleZ,
      ceiling,
      print_params:{
        infill_percentage:20
      }
    };
    try {
      dispatch(actions.setTimePricesLoading({ value: true, slicerNumber }));
      dispatch(actions.setQualityPriceLoading({ value: true, slicerNumber ,qualityId:1 }));
      const result = await getTimePrices(req);
      if (result) {
        dispatch(actions.setTimePrices({ value: result.materials, slicerNumber }));
        dispatch(actions.setQualityDetailTime({ value: Number(result.time), slicerNumber ,qualityId:1  }));
        dispatch(actions.setTimePricesLoading({ value: false, slicerNumber }));
        dispatch(actions.setQualityPriceLoading({ value: false, slicerNumber ,qualityId:1 }));
        dispatch(actions.setScaledStlPath({ value: result.scaledStlPath, slicerNumber }));
      }
    } catch (e) {
      dispatch(actions.setTimePrices({ value: [], slicerNumber }));
      dispatch(actions.setQualityDetailTime({ value: 0, slicerNumber,qualityId:1 }));
      dispatch(actions.setQualityPriceLoading({ value: false, slicerNumber ,qualityId:1 }));
      dispatch(actions.setTimePricesLoading({ value: false, slicerNumber }));
      console.error(e);
    }
  };

  const getQualityPriceContextByState = async ( slicerNumber,qualityId) => {
    dispatch(actions.setQualityPriceLoading({ value: true, slicerNumber ,qualityId }));
    let file=state.selectedFiles[slicerNumber];
    let infill=file.qualities[qualityId].infill;
    let quality_map={
      "1":'5bc59d53381bd536f72343b6',
      "2":'5bc59c83381bd536f72343b5',
      "3":'5bc59c1d381bd536f72343b4'
    }
    const {
      scaleX,
      scaleY,
      scaleZ,
      selectedStl,
      selectedMaterial,
      selectedColor,
    }=file;
    let req= {
      stl_path: selectedStl,
      x: scaleX,
      y: scaleY,
      z: scaleZ,
      material:selectedMaterial,
      color:selectedColor,
      ceiling:ceiling,
      quality:quality_map[qualityId],
      print_params:{
        infill_percentage:infill
      }
    }
     
    
    
    
    try {
      let result=state.selectedFiles[slicerNumber].pricing_cache?.[qualityId]?.[infill];
      if(!result)
        result = await getQualityPrice(req);
      
      if (result) {
        dispatch(actions.setQualityPrice({ value: result.price, slicerNumber , qualityId }));
        dispatch(actions.setQualityPriceCode({ value: result.price_code, slicerNumber , qualityId }));
        dispatch(actions.setQualityWeight({ value: result.weight, slicerNumber , qualityId }));
        dispatch(actions.setQualityDetailTime({ value: Number(result.time), slicerNumber ,qualityId }));
        dispatch(actions.setQualityPriceLoading({ value: false, slicerNumber ,qualityId }));
        dispatch(actions.setQuotationCacheEntry({ slicerNumber ,qualityId,infill,...result }));
      }
      return result;
    } catch (e) {
      dispatch(actions.setQualityWeight({ value: 0, slicerNumber , qualityId }));
      dispatch(actions.setQualityPrice({ value: 0, slicerNumber ,qualityId }));
      dispatch(actions.setQualityPriceCode({ value: '', slicerNumber ,qualityId }));
      dispatch(actions.setQualityDetailTime({ value: 0, slicerNumber ,qualityId }));
      dispatch(actions.setQualityPriceLoading({ value: false, slicerNumber ,qualityId }));
      console.error(e);
    }
  };

  

  const getMaterialsContext = async () => {
    try {
      const result = await getMaterials();
      if (result) {
        dispatch(actions.setMaterials(result.materials));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const setScaleXInitial = async (value, slicerNumber) => {
    dispatch(actions.setScaleXInitial({ value, slicerNumber }));
  };

  const setScaleX = async (value, slicerNumber) => {
    dispatch(actions.setScaleX({ value, slicerNumber }));
  };

  const setScaleY = async (value, slicerNumber) => {
    dispatch(actions.setScaleY({ value, slicerNumber }));
  };

  const setScaleZ = async (value, slicerNumber) => {
    dispatch(actions.setScaleZ({ value, slicerNumber }));
  };

  const setSelectedStl = async (value, slicerNumber) => {
    
    dispatch(actions.setSelectedStl({ value, slicerNumber }));
  };

  const setSelectedStep = async (value, slicerNumber) => {
    dispatch(actions.setSelectedStep({ value, slicerNumber }));
  };

  const setSelectedMaterial = async (value, slicerNumber) => {
    dispatch(actions.setSelectedMaterial({ value, slicerNumber }));
  };

  const setSelectedMaterialType = async (value, slicerNumber) => {
    dispatch(actions.setSelectedMaterialType({ value, slicerNumber }));
  };

  const setSelectedColor = async (value, slicerNumber) => {
    dispatch(actions.setSelectedColor({ value, slicerNumber }));
  };

  const setSelectedColorCode = async (value, slicerNumber) => {
    dispatch(actions.setSelectedColorCode({ value, slicerNumber }));
  };

  const setSelectedQuality = async (value, slicerNumber) => {
    dispatch(actions.setSelectedQuality({ value, slicerNumber }));
  };

  const setSelectedCode = async (value, slicerNumber) => {
    dispatch(actions.setSelectedCode({ value, slicerNumber }));
  };

  const setSelectedPrice = async (value, slicerNumber) => {
    dispatch(actions.setSelectedPrice({ value, slicerNumber }));
  };
  
  const setSlicerDetailWeight = async (value, slicerNumber) => {
    dispatch(actions.setSlicerDetailWeight({ value, slicerNumber }));
  };

  const setScaledStlPath = async (value, slicerNumber) => {
    dispatch(actions.setScaledStlPath({ value, slicerNumber }));
  };

  const setSelectedDeliveryTime = async (value, slicerNumber) => {
    dispatch(actions.setSelectedDeliveryTime({ value, slicerNumber }));
  };

  const setQuantity = async (value, slicerNumber) => {
    dispatch(actions.setQuantity({ value, slicerNumber }));
  };

  const setQualityPrice = async (value, slicerNumber,qualityId) => {
    dispatch(actions.setQualityPrice({ value, slicerNumber,qualityId }));
  };

  const setQualityInfill = async(value,slicerNumber,qualityId)=>{
    dispatch(actions.setQualityInfill({ value, slicerNumber,qualityId }));
    
    
  }

  const setQualityPriceCode = async (value, slicerNumber,qualityId) => {
    dispatch(actions.setQualityPriceCode({ value, slicerNumber,qualityId }));
  };
  const setQualityWeight = async (value, slicerNumber,qualityId) => {
    dispatch(actions.setQualityWeight({ value, slicerNumber,qualityId }));
  };


  

  const initializeSlicer = async (value) => {
    dispatch(actions.initializeSlicer(value));
  };

  const setStlBlob = async (value, slicerNumber) => {
    dispatch(actions.setStlBlob({ value, slicerNumber }));
  };

  const setSelectedFiles = async (value) => {
    dispatch(actions.setSelectedFiles(value));
  };
  const setSelectedPriceDataByState=async(slicerNumber)=>{
    dispatch(actions.setSelectedPriceDataByState({ slicerNumber }));
  };
  const setModelExtension=async(value,slicerNumber)=>{
    dispatch(actions.setModelExtension({ value,slicerNumber }));
  };

  return {
    getFilesContext,
    postFileContext,
    setScaleXInitial,
    setScaleX,
    setScaleY,
    setScaleZ,
    setSelectedStl,
    setSelectedStep,
    getVolumePricesContextByState,
    getTimePricesContextByState,
    getMaterialsContext,
    setSelectedMaterial,
    setSelectedMaterialType,
    setSelectedColor,
    setSelectedColorCode,
    setSelectedQuality,
    setSelectedCode,
    setSelectedPrice,
    setSelectedDeliveryTime,
    setQuantity,
    setQualityPrice,
    setQualityPriceCode,
    getQualityPriceContextByState,
    initializeSlicer,
    setStlBlob,
    setQualityWeight,
    setSelectedFiles,
    setSlicerDetailVolume,
    setSlicerDetailBoundingbox,
    setSlicerDetailDensity,
    setSelectedColorObject,
    setQualityDetailTime,
    setQualityInfill,
    setFiles,
    setSelectedPriceDataByState,
    setSlicerDetailWeight,
    setScaledStlPath,
    setModelExtension
  };
};

export default slicerPerformances;
