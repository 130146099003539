export const types = {
  GET_DISCOUNTS: 'GET_DISCOUNTS',
  SET_TOTAL_DISCOUNTS: 'SET_TOTAL_DISCOUNTS',
  GET_MATERIALS: 'GET_MATERIALS',
};

const actions = {
  setDiscounts: (payload) => ({
    type: types.GET_DISCOUNTS,
    payload,
  }),
  setTotalDiscounts: (payload) => ({
    type: types.SET_TOTAL_DISCOUNTS,
    payload,
  }),
  setMaterials: (payload) => ({
    type: types.GET_MATERIALS,
    payload,
  }),
};

export default actions;
