export const types = {
  SET_FILES: 'SET_FILES',
  SET_SCALEX_INITIAL: 'SET_SCALEX_INITIAL',
  SET_SCALEX: 'SET_SCALEX',
  SET_SCALEY: 'SET_SCALEY',
  SET_SCALEZ: 'SET_SCALEZ',
  SET_SELECTED_STL: 'SET_SELECTED_STL',
  SET_SELECTED_STEP: 'SET_SELECTED_STEP',
  SET_VOLUME_PRICES: 'SET_VOLUME_PRICES',
  SET_TIME_PRICES: 'SET_TIME_PRICES',
  SET_MATERIALS: 'SET_MATERIALS',
  SET_SELECTED_MATERIAL: 'SET_SELECTED_MATERIAL',
  SET_SELECTED_MATERIAL_TYPE: 'SET_SELECTED_MATERIAL_TYPE',
  SET_SELECTED_COLOR: 'SET_SELECTED_COLOR',
  SET_SELECTED_COLOR_CODE: 'SET_SELECTED_COLOR_CODE',
  SET_SELECTED_QUALITY: 'SET_SELECTED_QUALITY',
  SET_SELECTED_CODE: 'SET_SELECTED_CODE',
  SET_SELECTED_PRICE: 'SET_SELECTED_PRICE',
  SET_SLICER_DETAIL_WEIGHT:'SET_SLICER_DETAIL_WEIGHT',
  SET_SCALED_STL_PATH:'SET_SCALED_STL_PATH',
  SET_SELECTED_DELIVERY_TIME: 'SET_SELECTED_DELIVERY_TIME',
  SET_QUANTITY: 'SET_QUANTITY',
  SET_QUALITY_PRICE: 'SET_QUALITY_PRICE',
  SET_QUALITY_PRICE_CODE: 'SET_QUALITY_PRICE_CODE',
  SET_QUALITY_WEIGHT: 'SET_QUALITY_WEIGHT',

  SET_QUALITY_INFILL: 'SET_QUALITY_INFILL',
  
  INITIALIZE_SLICER: 'INITIALIZE_SLICER',
  PERCENT_COMPLETED: 'PERCENT_COMPLETED',
  SET_VOLUME_PRICES_LOADING: 'SET_VOLUME_PRICES_LOADING',
  SET_TIME_PRICES_LOADING: 'SET_TIME_PRICES_LOADING',
  SET_QUALITY_PRICE_LOADING: 'SET_QUALITY_PRICE_LOADING',
  SET_STL_BLOB: 'SET_STL_BLOB',
  SET_SELECTED_FILES: 'SET_SELECTED_FILES',
  SET_SLICER_DETAIL_VOLUME: 'SET_SLICER_DETAIL_VOLUME',
  SET_SLICER_DETAIL_BOUNDINGBOX: 'SET_SLICER_DETAIL_BOUNDINGBOX',
  SET_SLICER_DETAIL_DENSITY: 'SET_SLICER_DETAIL_DENSITY',
  SET_QUALITY_DETAIL_TIME: 'SET_QUALITY_DETAIL_TIME',
  SET_SLICER_SELECTED_COLOR_OBJECT: 'SET_SLICER_SELECTED_COLOR_OBJECT',
  SET_PRICING_CACHE_ENTRY:'SET_PRICING_CACHE_ENTRY',
  SET_SELECTED_PRICE_DATA_BY_STATE:'SET_SELECTED_PRICE_DATA_BY_STATE',
  SET_MODEL_EXTENSION:'SET_MODEL_EXTENSION'
};

const actions = {
  setFiles: (payload) => ({
    type: types.SET_FILES,
    payload,
  }),
  setScaleXInitial: (payload) => ({
    type: types.SET_SCALEX_INITIAL,
    payload,
  }),
  setScaleX: (payload) => ({
    type: types.SET_SCALEX,
    payload,
  }),
  setScaleY: (payload) => ({
    type: types.SET_SCALEY,
    payload,
  }),
  setScaleZ: (payload) => ({
    type: types.SET_SCALEZ,
    payload,
  }),
  setSelectedStl: (payload) => ({
    type: types.SET_SELECTED_STL,
    payload,
  }),
  setSelectedStep: (payload) => ({
    type: types.SET_SELECTED_STEP,
    payload,
  }),
  setVolumePrices: (payload) => ({
    type: types.SET_VOLUME_PRICES,
    payload,
  }),
  setTimePrices: (payload) => ({
    type: types.SET_TIME_PRICES,
    payload,
  }),
  setMaterials: (payload) => ({
    type: types.SET_MATERIALS,
    payload,
  }),
  setSelectedMaterial: (payload) => ({
    type: types.SET_SELECTED_MATERIAL,
    payload,
  }),
  setSelectedMaterialType: (payload) => ({
    type: types.SET_SELECTED_MATERIAL_TYPE,
    payload,
  }),
  setSelectedColor: (payload) => ({
    type: types.SET_SELECTED_COLOR,
    payload,
  }),
  setSelectedColorCode: (payload) => ({
    type: types.SET_SELECTED_COLOR_CODE,
    payload,
  }),
  setSelectedQuality: (payload) => ({
    type: types.SET_SELECTED_QUALITY,
    payload,
  }),
  setSelectedCode: (payload) => ({
    type: types.SET_SELECTED_CODE,
    payload,
  }),
  setSlicerDetailWeight: (payload) => ({
    type: types.SET_SLICER_DETAIL_WEIGHT,
    payload,
  }),
  setScaledStlPath: (payload) => ({
    type: types.SET_SCALED_STL_PATH,
    payload,
  }),
  setSelectedPrice: (payload) => ({
    type: types.SET_SELECTED_PRICE,
    payload,
  }),
  setSelectedDeliveryTime: (payload) => ({
    type: types.SET_SELECTED_DELIVERY_TIME,
    payload,
  }),
  setQuantity: (payload) => ({
    type: types.SET_QUANTITY,
    payload,
  }),
  setQualityPrice: (payload) => ({
    type: types.SET_QUALITY_PRICE,
    payload,
  }),
  setQualityPriceCode: (payload) => ({
    type: types.SET_QUALITY_PRICE_CODE,
    payload,
  }),
  setQualityWeight: (payload) => ({
    type: types.SET_QUALITY_WEIGHT,
    payload,
  }),
  setQualityPriceLoading: (payload) => ({
    type: types.SET_QUALITY_PRICE_LOADING,
    payload,
  }),
  initializeSlicer: (payload) => ({
    type: types.INITIALIZE_SLICER,
    payload,
  }),
  setPercentCompleted: (payload) => ({
    type: types.PERCENT_COMPLETED,
    payload,
  }),
  setVolumePricesLoading: (payload) => ({
    type: types.SET_VOLUME_PRICES_LOADING,
    payload,
  }),
  setTimePricesLoading: (payload) => ({
    type: types.SET_TIME_PRICES_LOADING,
    payload,
  }),
  
  setStlBlob: (payload) => ({
    type: types.SET_STL_BLOB,
    payload,
  }),
  setSelectedFiles: (payload) => ({
    type: types.SET_SELECTED_FILES,
    payload,
  }),
  setSlicerDetailVolume: (payload) => ({
    type: types.SET_SLICER_DETAIL_VOLUME,
    payload,
  }),
  setSlicerDetailBoundingbox: (payload) => ({
    type: types.SET_SLICER_DETAIL_BOUNDINGBOX,
    payload,
  }),
  setSlicerDetailDensity: (payload) => ({
    type: types.SET_SLICER_DETAIL_DENSITY,
    payload,
  }),
  setSelectedColorObject: (payload) => ({
    type: types.SET_SLICER_SELECTED_COLOR_OBJECT,
    payload,
  }),
  setQualityDetailTime: (payload) => ({
    type: types.SET_QUALITY_DETAIL_TIME,
    payload,
  }),
  setQualityInfill:(payload)=>({
    type:types.SET_QUALITY_INFILL,
    payload
  }),
  setQuotationCacheEntry:(payload)=>({
    type:types.SET_PRICING_CACHE_ENTRY,
    payload
  }),
  
  setSelectedPriceDataByState: (payload) => ({
    type: types.SET_SELECTED_PRICE_DATA_BY_STATE,
    payload,
  }),
  setModelExtension:(payload)=>({
    type:types.SET_MODEL_EXTENSION,
    payload
  })
};

export default actions;
