export const validateEmail = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validateName = (value) => {
  const charRange = [
    '[\\s,\u06A9\u06AF\u06C0\u06CC\u060C',
    '\u062A\u062B\u062C\u062D\u062E\u062F',
    '\u063A\u064A\u064B\u064C\u064D\u064E',
    '\u064F\u067E\u0670\u0686\u0698\u200C',
    '\u0621-\u0629\u0630-\u0639\u0641-\u0654]',
  ].join();
  const regex = new RegExp(`^(${charRange}|[a-zA-Z])*$`);
  return regex.test(value);
};

export const validateBuisnessName = (value) => {
  const re = /^[a-zA-Z0-9]*$/;
  return re.test(String(value));
};
