import axios from 'axios';
const { REACT_APP_HUB_SERVICE_URL } = process.env;

export const getHubs = async (data) => {
  const response = await axios
    .post(`${REACT_APP_HUB_SERVICE_URL}/admin/hub/filter/colors`, data)
    .then((response) => response.data.payload);
  return response;
};

export const getHub = async (hubId) => {
  const response = await axios
    .get(`${REACT_APP_HUB_SERVICE_URL}/admin/hub/${hubId}`)
    .then((response) => response.data.payload);
  return response;
};

export const createHub = async (data) => {
  const response = await axios
    .post(`${REACT_APP_HUB_SERVICE_URL}/admin/hub`, data)
    .then((response) => response.data.payload);
  return response;
};

export const editHub = async (data, id) => {
  const response = await axios
    .put(`${REACT_APP_HUB_SERVICE_URL}/admin/hub/${id}`, data)
    .then((response) => response.data.meta.status);
  return response;
};
