export const checkPermission = ({ access, selectedRole, roles }) => {
  let result = false;
  roles.map((role) => {
    if (role.family === selectedRole) {
      result = role.permissions.includes(access);
    }
  });
  return result;
};

export const getSelectedRoleIndex = ({ selectedRole, roles }) => {
  let result = 0;
  roles.map((role, index) => {
    if (role.family === selectedRole) {
      result = index + 1;
    }
  });
  return result;
};
