import React, { useReducer,useEffect,useState } from 'react';
import MainContext from './MainContext';

import loginReducer, { key as loginKey } from 'containers/login/container/reducer';
import ordersReducer, { key as ordersKey } from 'containers/orders/container/reducer';
import materialsReducer, { key as materialsKey } from 'containers/materials/container/reducer';
import usersReducer, { key as usersKey } from 'containers/users/container/reducer';
import discountsReducer, { key as discountsKey } from 'containers/discounts/container/reducer';
import hubsReducer, { key as hubsKey } from 'containers/factories/container/reducer';
import settingReducer, { key as settingKey } from 'containers/settings/container/reducer';
import toasterReducer, { key as toasterKey } from 'components/toaster/container/reducer';
import boardingReducer, { key as boardingKey } from 'containers/boarding/container/reducer';
import cartReducer, { key as cartKey } from 'containers/bascket/container/reducer';
import slicerReducer, { key as slicerKey } from 'containers/slicer/container/reducer';
import checkoutReducer, { key as checkoutKey } from 'containers/checkout/container/reducer';

import LoginPerformances from 'containers/login/container/performances';
import OrdersPerformances from 'containers/orders/container/performances';
import MaterialsPerformances from 'containers/materials/container/performances';
import usersPerformances from 'containers/users/container/performances';
import discountsPerformances from 'containers/discounts/container/performances';
import hubsPerformances from 'containers/factories/container/performances';
import settingPerformances from 'containers/settings/container/performances';
import ToasterPerformances from 'components/toaster/container/performances';
import BoardingPerformances from 'containers/boarding/container/performances';
import CartPerformances from 'containers/bascket/container/performances';
import SlicerPerformances from 'containers/slicer/container/performances';
import CheckoutPerformances from 'containers/checkout/container/performances';

import combineReducer from 'utils/combineReducer';

const AppProvider = (props) => {
  
  const initialValue = {
    taskQueue:[],
    [loginKey]: {
      loginState: 1,
      selectedHUbModeId: '',
      selectedHUbModeType: '',
      profile: {},
      token: '',
      socket: null,
      notifications: [],
      email: '',
      redirect: '',
      roles: [],
      selectedRole: '',
      familySize: 1000000,
      usedSize: 0,
      familyCurrency: 0,
      familyType: 1,
    },
    [ordersKey]: {
      invoices: [],
      totalInvoices: 0,
      orderModalState: 0,
      orderHubs: [],
    },
    [materialsKey]: {
      materials: [],
      materialImageLoading: false,
    },
    [usersKey]: {
      users: [],
      totalUsers: 0,
      userProfile: {},
      userCredit: 0,
      userFiles: [],
      userInvoices: 0,
    },
    [discountsKey]: {
      discounts: [],
      totalDiscounts: 0,
      materials: [],
    },
    [hubsKey]: {
      hub: {},
      hubs: [],
      totalHubs: 0,
      materials: [],
    },
    [settingKey]: {
      permissions: [],
      roles: [],
      totalRoles: 0,
      paymentMethods: [],
      paymentMethodTypes: [],
      countries: [],
      locations: [],
      shippings: [],
      taxes: [],
    },
    [toasterKey]: {
      toasterMessages: [],
    },
    [boardingKey]: {
      boardingState: 1,
      boardingOpen: false,
      boardingFirstLaunch: true,
    },
    [slicerKey]: {
      queedTasks:[],
      files: [],
      materials: [],
      selectedFiles: [
        {
          slicerId: 'a' + Math.random(),
          scaleXInitial: 0,
          scaleX: 0,
          scaleY: 0,
          scaleZ: 0,
          selectedStl: '',
          selectedStep: 1,
          volumePrices: [],
          volumePricesLoading: false,
          timePrices: [],
          timePricesLoading: false,
          selectedMaterial: '',
          selectedMaterialType: '',
          selectedColor: 'rgb(0,0,0)',
          selectedColorCode: '',
          selectedQuality: 1,
          selectedCode: '',
          selectedPrice: 0,
          selectedDeliveryTime: 0,
          quantity: 1,
          pricing_cache:{},
          modelExtension:'',
          qualities:{
              "1":{
                price:0,
                price_code:'',
                loading:false,
                infill:20,
                time:0,
                weight:0
              },
              "2":{
                price:0,
                price_code:'',
                loading:false,
                infill:20,
                time:0,
                weight:0
              },
              "3":{
                price:0,
                price_code:'',
                loading:false,
                infill:20,
                time:0,
                weight:0
              }
              
          },
          percentCompleted: -1,
          stlBlob: '',
          selectedColorObject: {},
          scaledStlPath:'',
          slicerDetail: {
            volume: 0,
            boundingBox: 0,
            density: 0,
            weight:0
          },
        },
      ],
    },
    [checkoutKey]: {
      addresses: [],
      finalPrice: 0,
      checkoutLoading: false,
      countries: [],
      selectedStep: 1,
      tax: 0,
      shipping: {},
      pickup: [],
      selectedDelivery: {
        type: '',
        shippingOrPickupId: '',
        optionId: '',
        price: 0,
      },
      payments: [],
      selectedPayment: '',
      discount: {},
      discountAmount: 0,
      shippingLoading: false,
    },
    [cartKey]: {
      basketOrders: [],
      checkoutInvoice: [],
      materials: [],
      loadingCreateOrder: false,
    },
  };

  const rootReducer = combineReducer({
    [loginKey]: loginReducer,
    [ordersKey]: ordersReducer,
    [materialsKey]: materialsReducer,
    [usersKey]: usersReducer,
    [discountsKey]: discountsReducer,
    [hubsKey]: hubsReducer,
    [settingKey]: settingReducer,
    [toasterKey]: toasterReducer,
    [boardingKey]: boardingReducer,
    [cartKey]: cartReducer,
    [slicerKey]: slicerReducer,
    [checkoutKey]: checkoutReducer,
  });
  
  const [state, dispatch] = useReducer(rootReducer, initialValue);
  
  let [taskQueue,setTaskQueue]=useState([]);
  
  
  function postTask(task){
    setTaskQueue((prev)=>[...prev,task]);
  }

  const myInitialState = {
    ...state,
    postTask,
    [loginKey]: {
      ...state[loginKey],
      ...LoginPerformances(dispatch),
    },
    [ordersKey]: {
      ...state[ordersKey],
      ...OrdersPerformances(dispatch),
    },
    [materialsKey]: {
      ...state[materialsKey],
      ...MaterialsPerformances(dispatch),
    },
    [usersKey]: {
      ...state[usersKey],
      ...usersPerformances(dispatch),
    },
    [discountsKey]: {
      ...state[discountsKey],
      ...discountsPerformances(dispatch),
    },
    [hubsKey]: {
      ...state[hubsKey],
      ...hubsPerformances(dispatch),
    },
    [settingKey]: {
      ...state[settingKey],
      ...settingPerformances(dispatch),
    },
    [toasterKey]: {
      ...state[toasterKey],
      ...ToasterPerformances(dispatch),
    },
    [boardingKey]: {
      ...state[boardingKey],
      ...BoardingPerformances(dispatch),
    },
    [cartKey]: {
      ...state[cartKey],
      ...CartPerformances(dispatch),
    },
    [slicerKey]: {
      ...state[slicerKey],
      ...SlicerPerformances(dispatch,state[slicerKey],state[loginKey].profile.ceiling),
    },
    [checkoutKey]: {
      ...state[checkoutKey],
      ...CheckoutPerformances(dispatch),
    },
  };

  useEffect( ()=>{
    async function runATask(){
      if(taskQueue.length){
        let task=taskQueue[0];
        setTaskQueue(taskQueue.slice(1,taskQueue.length));
        await task(myInitialState);
      }
    }
    runATask();
  },[taskQueue])

  return (
    <MainContext.Provider displayName="Main Context" value={myInitialState}>
      {props.children}
    </MainContext.Provider>
  );
};

export default AppProvider;
