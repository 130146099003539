import axios from 'axios';
const { REACT_APP_STL_SERVICE } = process.env;

export const getMaterials = async () => {
  const response = await axios
    .get(`${REACT_APP_STL_SERVICE}/materials?page=1&limit=20&order=1&skip=0&sort=_id`)
    .then((response) => response.data.payload);
  return response;
};

export const uploadMaterialImage = async ({ id, data }) => {
  const response = await axios
    .put(`${REACT_APP_STL_SERVICE}/image/material/${id}`, data)
    .then((response) => response.data.payload);
  return response;
};

export const uploadColorImage = async ({ data }) => {
  const response = await axios
    .post(`${REACT_APP_STL_SERVICE}/image/colors/image`, data)
    .then((response) => response.data.payload);
  return response;
};

export const createMaterial = async ({ data }) => {
  const response = await axios
    .post(`${REACT_APP_STL_SERVICE}/materials`, data)
    .then((response) => response.data.payload)
    .catch((error) => {
      console.log('error.response', error.response);
      throw { message: error.response.data.meta.message, status: error.response.data.meta.status };
    });
  return response;
};

export const createColor = async ({ data, id }) => {
  console.log("data", data);
  const response = await axios
    .post(`${REACT_APP_STL_SERVICE}/materials/${id}/colors`, data)
    .then((response) => response.data.payload)
    .catch((error) => {
      throw { message: error.response.data.meta.message, status: error.response.data.meta.status };
    });
  return response;
};

export const createColorCopy = async ({ data, id }) => {
  console.log("data", data);
  const response = await axios
    .post(`${REACT_APP_STL_SERVICE}/materials/${id}/colors/copy`, data)
    .then((response) => response.data.payload)
    .catch((error) => {
      throw { message: error.response.data.meta.message, status: error.response.data.meta.status };
    });
  return response;
};


export const editMaterial = async ({ data, id }) => {
  const response = await axios
    .put(`${REACT_APP_STL_SERVICE}/materials/${id}`, data)
    .then((response) => response.data.payload)
    .catch((error) => {
      throw { message: error.response.data.meta.message, status: error.response.data.meta.status };
    });
  return response;
};

export const editColor = async ({ data, materialId, colorId }) => {
  const response = await axios
    .put(`${REACT_APP_STL_SERVICE}/materials/${materialId}/colors/${colorId}`, data)
    .then((response) => response.data.payload)
    .catch((error) => {
      throw { message: error.response.data.meta.message, status: error.response.data.meta.status };
    });
  return response;
};
