import { types } from './actions';

const reducer = (state, action) => {
  switch (action.type) {
    case types.GET_HUB:
      return { ...state, hub: action.payload };
    case types.GET_HUBS:
      return { ...state, hubs: action.payload };
    case types.UPDATE_HUBS:
      return { ...state, hubs: [...state.hubs, action.payload] };
    case types.SET_TOTAL_HUBS:
      return { ...state, totalHubs: action.payload };
    case types.HUBS_GET_MATERIALS:
      return { ...state, materials: action.payload };
    default:
      return state;
  }
};

export const key = 'hubs';
export default reducer;
