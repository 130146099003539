import actions from './actions';
import toasterActions from 'components/toaster/container/actions';
import { getHubs, createHub, editHub, getHub } from './api';
import { getMaterials } from 'containers/materials/container/api';

const hubsPerformances = (dispatch) => {
  const getHubsContext = async (data) => {
    try {
      const result = await getHubs(data);
      if (result) {
        let hubs = [];
        for (let key in result) {
          hubs.push(result[key]);
        }
        dispatch(actions.setHubs(hubs));
        // dispatch(actions.setTotalHubs(result.total))
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getHubContext = async (hubId) => {
    try {
      const result = await getHub(hubId);
      if (result) {
        dispatch(actions.setHub(result));
      }
    } catch (e) {
      dispatch(toasterActions.updateToaster({ type: 'error', message: 'Hub not found' }));
      console.error(e);
    }
  };

  const emptyHubContext = async () => {
    dispatch(actions.setHub([]));
  };

  const createHubContext = async (data) => {
    try {
      const result = await createHub(data);
      if (result) {
        // await getHubsContext()
        dispatch(toasterActions.updateToaster({ type: 'success', message: 'Hub created' }));
        dispatch(
          actions.updateHubs({
            count: 0,
            enable: true,
            name: result.Hub.name,
            rate: 0,
            _id: result.Hub._id,
          })
        );
      }
    } catch (e) {
      dispatch(toasterActions.updateToaster({ type: 'error', message: 'Hub not created' }));
    }
  };
  const editHubContext = async (data, id) => {
    try {
      const result = await editHub(data, id);
      if (result) {
        // await getHubsContext()
        dispatch(toasterActions.updateToaster({ type: 'success', message: 'Hub edited' }));
      }
    } catch (e) {
      dispatch(toasterActions.updateToaster({ type: 'error', message: 'Hub not edited' }));
    }
  };
  const setHubsContext = async (data) => {
    try {
      dispatch(actions.setHubs(data));
    } catch (e) {
      console.error(e);
    }
  };
  const getMaterialsContext = async () => {
    try {
      const result = await getMaterials();
      if (result) {
        dispatch(actions.setMaterials(result.materials));
      }
    } catch (e) {
      console.error(e);
    }
  };
  return {
    getHubsContext,
    setHubsContext,
    getMaterialsContext,
    getHubContext,
    emptyHubContext,
    createHubContext,
    editHubContext,
  };
};

export default hubsPerformances;
