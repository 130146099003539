import React, { useContext } from 'react';
import { ThemeContext } from 'context/themeContext';
import { Wrapper, ImageWrapper, Step1Icon, Content, Title, Text, Inputs } from './style';
import Input from 'components/input';

const Step1 = (props) => {
  const { theme } = useContext(ThemeContext);
  const { display, onChange, businessName } = props;
  return (
    <Wrapper display={display}>
      <ImageWrapper theme={theme}>
        <Step1Icon />
      </ImageWrapper>
      <Content>
        <Title theme={theme}>Enter your business name</Title>
        <Text theme={theme}>
          This name will be used for your online 3D print shop. Your customer contact you by your
          own URL address.
        </Text>
        <Inputs>
          <Input
            placeholder="Business name"
            onChange={onChange}
            inputTarget="business-name"
            value={businessName}
            size="full"
            validate={true}
          />
        </Inputs>
      </Content>
    </Wrapper>
  );
};

export default Step1;
