import axios from 'axios';
const {
  REACT_APP_AUTHENTICATION_SERVICE_URL,
  REACT_APP_PAYMENT_SERVICE_URL,
  REACT_APP_ORDERS_SERVICE_URL,
} = process.env;

export const getPolicies = async () => {
  const response = await axios
    .get(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/policy`)
    .then((response) => response.data.payload);
  return response;
};

export const updatePolicies = async (data) => {
  const response = await axios
    .put(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/policy`, data)
    .then((response) => response.data.payload);
  return response;
};

export const getRoles = async (reqData) => {
  const response = await axios
    .get(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/role?page=${reqData.pageNumber}`)
    .then((response) => response.data.payload);
  return response;
};

export const getRoleByHub = async (hubId, hubType) => {
  const response = await axios
    .get(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/role/by-hub-and-type/${hubId}/${hubType}`)
    .then((response) => response.data.payload);
  return response;
};

export const updateRoleByHub = async (hubId, hubType, data) => {
  const response = await axios
    .put(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/role/by-hub-and-type/${hubId}/${hubType}`, data)
    .then((response) => response.data.payload);
  return response;
};

export const createRole = async (data) => {
  const response = await axios
    .post(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/role`, data)
    .then((response) => response.data.payload);
  return response;
};

export const editRole = async ({ data, id }) => {
  const response = await axios
    .put(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/role/${id}`, data)
    .then((response) => response.data.payload);
  return response;
};

export const deleteRole = async ({id}) => {
  const response = await axios 
    .delete(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/role/delete-role/${id}`)
    .then((response) => response.data.payload);
  return response;
}

export const getPermissions = async () => {
  const response = await axios
    .get(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/new-permission`)
    .then((response) => response.data.payload);
  return response;
};

export const uploadLogoImage = async ({ data }) => {
  const response = await axios
    .put(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/profile/logo`, data)
    .then((response) => response.data.payload);
  return response;
};

export const deleteProfile = async () => {
  const response = await axios
    .delete(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/profile/delete-profile`)
    .then((response) => response.data);
  return response;
};

export const getPaymentMethods = async () => {
  const response = await axios
    .get(`${REACT_APP_PAYMENT_SERVICE_URL}/admin/payment-method/admin`)
    .then((response) => response.data.payload);
  return response;
};

export const createPaymentMethod = async (data) => {
  const response = await axios
    .post(`${REACT_APP_PAYMENT_SERVICE_URL}/admin/payment-method`, data)
    .then((response) => response.data.payload);
  return response;
};

export const editPaymentMethod = async ({ data, id }) => {
  const response = await axios
    .put(`${REACT_APP_PAYMENT_SERVICE_URL}/admin/payment-method/${id}`, data)
    .then((response) => response.data.payload);
  return response;
};

export const editPaymentMethodType = async ({ data, id }) => {
  const response = await axios
    .put(`${REACT_APP_PAYMENT_SERVICE_URL}/admin/payment-method/type/${id}`, data)
    .then((response) => response.data.payload);
  return response;
};

export const getCountries = async () => {
  const response = await axios
    .get(`${REACT_APP_ORDERS_SERVICE_URL}/region/countries`)
    .then((response) => response.data.payload);
  return response;
};

export const getStates = async (id) => {
  const response = await axios
    .get(`${REACT_APP_ORDERS_SERVICE_URL}/region/states/${id}`)
    .then((response) => response.data.payload);
  return response;
};

export const getCities = async (id) => {
  const response = await axios
    .get(`${REACT_APP_ORDERS_SERVICE_URL}/region/cities/${id}`)
    .then((response) => response.data.payload);
  return response;
};

export const getRegionByName = async (name) => {
  const response = await axios
    .get(`${REACT_APP_ORDERS_SERVICE_URL}/region/search-name/${name}`)
    .then((response) => response.data.payload);
  return response;
};

export const getLocations = async () => {
  const response = await axios
    .get(`${REACT_APP_ORDERS_SERVICE_URL}/location`)
    .then((response) => response.data.payload);
  return response;
};

export const createLocation = async (data) => {
  const response = await axios
    .post(`${REACT_APP_ORDERS_SERVICE_URL}/location`, data)
    .then((response) => response.data.payload);
  return response;
};

export const editLocation = async ({ data, id }) => {
  const response = await axios
    .put(`${REACT_APP_ORDERS_SERVICE_URL}/location/${id}`, data)
    .then((response) => response.data.payload);
  return response;
};

export const getShipping = async () => {
  const response = await axios
    .get(`${REACT_APP_ORDERS_SERVICE_URL}/area-zone`)
    .then((response) => response.data.payload);
  return response;
};

export const createShipping = async (data) => {
  const response = await axios
    .post(`${REACT_APP_ORDERS_SERVICE_URL}/area-zone`, data)
    .then((response) => response.data.payload);
  return response;
};

export const editShipping = async ({ data, id }) => {
  const response = await axios
    .put(`${REACT_APP_ORDERS_SERVICE_URL}/area-zone/${id}`, data)
    .then((response) => response.data.payload);
  return response;
};

export const getTaxes = async () => {
  const response = await axios
    .get(`${REACT_APP_ORDERS_SERVICE_URL}/tax`)
    .then((response) => response.data.payload);
  return response;
};

export const createTax = async (data) => {
  const response = await axios
    .post(`${REACT_APP_ORDERS_SERVICE_URL}/tax`, data)
    .then((response) => response.data.payload);
  return response;
};

export const editTax = async ({ data, id }) => {
  const response = await axios
    .put(`${REACT_APP_ORDERS_SERVICE_URL}/tax/${id}`, data)
    .then((response) => response.data.payload);
  return response;
};
