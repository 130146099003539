import actions from './actions';
import toasterActions from 'components/toaster/container/actions';
import { getMaterials, uploadMaterialImage, createMaterial, editMaterial } from './api';

const materialsPerformances = (dispatch) => {
  const setMatrialsContext = async (materials) => {
    dispatch(actions.setMaterials(materials));
  };

  const setMatrialsImageLoading = async (value) => {
    dispatch(actions.setMaterialsImageLoading(value));
  };

  const getMatrialsContext = async () => {
    try {
      const result = await getMaterials();
      if (result) {
        dispatch(actions.setMaterials(result.materials));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const uploadMaterialImageContext = async ({ id, data, materials, selectedMaterial }) => {
    try {
      dispatch(actions.setMaterialsImageLoading(true));
      const result = await uploadMaterialImage({ id, data });
      materials[selectedMaterial].material_image = result.material_image;
      if (result) {
        dispatch(actions.setMaterials(materials));
      }
      setTimeout(() => {
        dispatch(actions.setMaterialsImageLoading(false));
      }, 4000);
    } catch (e) {
      console.error(e);
    }
  };

  const createMaterialContext = async ({ data, materials }) => {
    try {
      dispatch(actions.setMaterialsImageLoading(true));
      const result = await createMaterial({ data });
      let helper = [...materials];
      helper.push(result);
      if (result) {
        dispatch(actions.setMaterials(helper));
        dispatch(toasterActions.updateToaster({ type: 'success', message: 'Material created' }));
      }
      dispatch(actions.setMaterialsImageLoading(false));
      return true;
    } catch (e) {
      dispatch(actions.setMaterialsImageLoading(false));
      if (e.message === 'material.exists') {
        dispatch(
          toasterActions.updateToaster({ type: 'error', message: 'Technology name exists' })
        );
      } else {
        dispatch(toasterActions.updateToaster({ type: 'error', message: 'Material not created' }));
      }
      return false;
    }
  };

  const editMaterialContext = async ({ data, id, materials, selectedMaterial }) => {
    try {
      // dispatch(actions.setMaterialsImageLoading(true))
      const result = await editMaterial({ data, id });
      let materialsHelper = [...materials];
      materialsHelper[selectedMaterial] = result;
      if (result) {
        dispatch(actions.setMaterials(materialsHelper));
        dispatch(toasterActions.updateToaster({ type: 'success', message: 'technology edited' }));
      }
      // dispatch(actions.setMaterialsImageLoading(false))
    } catch (e) {
      dispatch(toasterActions.updateToaster({ type: 'error', message: 'technology not edited' }));
      console.error(e);
    }
  };

  return {
    setMatrialsContext,
    getMatrialsContext,
    setMatrialsImageLoading,
    uploadMaterialImageContext,
    createMaterialContext,
    editMaterialContext,
  };
};

export default materialsPerformances;
