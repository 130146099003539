import axios from 'axios';
const {
  REACT_APP_AUTHENTICATION_SERVICE_URL,
  REACT_APP_PAYMENT_SERVICE_URL,
  REACT_APP_UPLOAD_SERVICE_URL,
  REACT_APP_ORDERS_SERVICE_URL
} = process.env;

export const getUsers = async (reqData) => {
  const response = await axios
    .get(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/admin/user/list?page=${reqData.pageNumber}`)
    .then((response) => response.data.payload)
    .catch((err) => {
      console.log(err);
    });
  return response;
};

export const getUsersByFilter = async (reqData) => {
  const response = await axios
    .post(
      `${REACT_APP_AUTHENTICATION_SERVICE_URL}/admin/user/filter-name/list?page=${reqData.pageNumber}`,
      { name: reqData.userFilter }
    )
    .then((response) => response.data.payload);
  return response;
};

export const getUserProfile = async (reqData) => {
  const response = await axios
    .get(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/admin/user/${reqData.userId}/profile`)
    .then((response) => response.data.payload);
  return response;
};

export const getUserCredit = async (reqData) => {
  const response = await axios
    .get(`${REACT_APP_PAYMENT_SERVICE_URL}/admin/credit/user/${reqData.userId}`)
    .then((response) => response.data.payload);
  return response;
};

export const updateUserCredit = async (data) => {
  const response = await axios
    .post(`${REACT_APP_PAYMENT_SERVICE_URL}/admin/credit/chargee`, data)
    .then((response) => response.data.payload);
  return response;
};
export const getUserTransactions=async({userId})=>{
  const response=await axios
    .get(`${REACT_APP_PAYMENT_SERVICE_URL}/admin/transaction/user/${userId}`)
    .then((response) => response.data.payload);
  return response;
}

export const getUserFiles = async (reqData) => {
  const response = await axios
    .get(`${REACT_APP_UPLOAD_SERVICE_URL}/upload/admin/user/${reqData.userId}`)
    .then((response) => response.data.payload);
  return response;
};

export const editUserPersonalInfo = async ({ userId, data }) => {
  const response = await axios
    .put(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/admin/user/${userId}`, data)
    .then((response) => response.data.payload);
  return response;
};

export const editUserPassword = async ({ userId, data }) => {
  const response = await axios
    .post(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/admin/user/${userId}/change-password`, data)
    .then((response) => response.data.payload);
  return response;
};

export const getUserInvoices = async (userId) => {
  const response = await axios
    .get(`${REACT_APP_ORDERS_SERVICE_URL}/admin/invoice/user/invoices/${userId}`)
    .then((response) => response.data.payload);
  return response;
};
