import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { BackgroundContext } from 'context/backgroundContext';
import { ThemeContext } from 'context/themeContext';
import { acceptNewRole, rejectNewRole } from 'containers/login/container/api';

const Wrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  transition: right 0.3s;
  background: ${(props) => (props.theme ? props.theme.primaryBackground : 'black')};
`;

const Content = styled.div`
  position: fixed;
  width: 100vw;
  height: calc(100vh - 4.008vw);
  top: 4.008vw;
  left: 0;
  box-sizing: border-box;
`;

const LeftWrapper = styled.div`
  position: absolute;
  width: ${(props) => (props.leftWidth ? 2 * props.leftWidth + 'vw' : '0')};
  height: 500vh;
  top: -185vh;
  left: ${(props) => (props.leftWidth ? -props.leftWidth + 'vw' : '0')};
  transition: right 0.3s;
  background: ${(props) => (props.theme ? props.theme.SecondaryBackground : 'black')};
  transform: ${(props) =>
    props.rotateAngle ? 'rotate(' + props.rotateAngle + 'deg)' : 'rotate(0deg)'};
  transition: left 0.4s ease-in-out, width 0.4s ease-in-out, height 0.4s ease-in-out,
    transform 0.4s ease-in-out;
`;

function Background(props) {
  const { leftWidth, setLeftWidth, rotateAngle, setRotateAngle } = useContext(BackgroundContext);
  const { theme, toggleTheme, darkThemeSelected } = useContext(ThemeContext);
  const [showChild, setShowChild] = useState(false);

  useEffect(() => {
    checkQuery();
  }, []);

  const checkQuery = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const newrole = urlParams.get('newrole');
      const reject = urlParams.get('reject');
      if (newrole && !reject) {
        await acceptNewRole(newrole);
      }
      if (newrole && reject) {
        await rejectNewRole(newrole);
      }
      setShowChild(true);
    } catch {
      setShowChild(true);
    }
  };

  return (
    <Wrapper theme={theme}>
      <LeftWrapper leftWidth={leftWidth} rotateAngle={rotateAngle} theme={theme} />
      {showChild && <Content>{props.children}</Content>}
    </Wrapper>
  );
}

export default React.memo(Background);
