import { types } from './actions';

const reducer = (state, action) => {
  let stateHelper = { ...state };
  function computeSelectedPriceAndCode(selectedFileState){
    
    let {
      selectedMaterial,
      selectedColor,
      selectedMaterialType,
      selectedQuality
    }=selectedFileState;
    if(selectedMaterialType=='time'&&selectedQuality){
      let qualityInfo=selectedFileState.qualities[selectedQuality];
      return {
        time:qualityInfo.time,
        price_code:qualityInfo.price_code,
        price:qualityInfo.price,
        weight:qualityInfo.weight
      }
    }
    if(selectedMaterialType!='time'&&selectedColor&&selectedMaterial){
      let color=selectedFileState.volumePrices.find((x)=>x._id==selectedMaterial)
                                                 .material_colors.find((x)=>x._id==selectedColor);
      return {
        price_code:color.price_code,
        price:color.price,
        weight:color.weight
      }
    }
  }
  switch (action.type) {
    case types.SET_FILES:
      return { ...state, files: action.payload };
    case types.SET_SCALEX_INITIAL:
      stateHelper.selectedFiles[action.payload.slicerNumber].scaleXInitial = action.payload.value;
      return stateHelper;

    case types.SET_SCALEX:
      stateHelper.selectedFiles[action.payload.slicerNumber].scaleX = action.payload.value;
      stateHelper.selectedFiles[action.payload.slicerNumber].pricing_cache={};
      return stateHelper;

    case types.SET_SCALEY:
      stateHelper.selectedFiles[action.payload.slicerNumber].scaleY = action.payload.value;
      stateHelper.selectedFiles[action.payload.slicerNumber].pricing_cache={};
      return stateHelper;

    case types.SET_SCALEZ:
      stateHelper.selectedFiles[action.payload.slicerNumber].scaleZ = action.payload.value;
      stateHelper.selectedFiles[action.payload.slicerNumber].pricing_cache={};
      return stateHelper;

    case types.SET_SELECTED_STL:
      stateHelper.selectedFiles[action.payload.slicerNumber].selectedStl = action.payload.value;
      stateHelper.selectedFiles[action.payload.slicerNumber].pricing_cache={};
      return { ...state, selectedStl: action.payload };

    case types.SET_SELECTED_STEP:
      stateHelper.selectedFiles[action.payload.slicerNumber].selectedStep = action.payload.value;
      return stateHelper;

    case types.SET_VOLUME_PRICES:
      stateHelper.selectedFiles[action.payload.slicerNumber].volumePrices = action.payload.value;
      return stateHelper;

    case types.SET_VOLUME_PRICES_LOADING:
      stateHelper.selectedFiles[action.payload.slicerNumber].volumePricesLoading =
        action.payload.value;
      return stateHelper;

    case types.SET_TIME_PRICES:
      stateHelper.selectedFiles[action.payload.slicerNumber].timePrices = action.payload.value;

      //this block sets the pricing cache for 20% infill for first quality
      //add sets quality 1 if it's infill is 20%
      let {
        selectedMaterial,
        selectedColor,
        selectedMaterialType
      }=stateHelper.selectedFiles[action.payload.slicerNumber];
      if(selectedMaterialType=='time'){
        let material=stateHelper.selectedFiles[action.payload.slicerNumber].timePrices.find((x)=>x._id==selectedMaterial);
        if(material){
          let time=material.time;
          let color=material.material_colors.find((x)=>x._id==selectedColor);
          
          if(color?.price&&color?.price_code){
            stateHelper.selectedFiles[action.payload.slicerNumber].pricing_cache[1]={};
            stateHelper.selectedFiles[action.payload.slicerNumber].pricing_cache[1][20]={
              price:color.price,
              price_code:color.price_code,
              time:time,
              weight:color.weight
            }
            if(stateHelper.selectedFiles[action.payload.slicerNumber].qualities[1].infill==20){
              stateHelper.selectedFiles[action.payload.slicerNumber].qualities[1]={
                infill:20,
                price:color.price,
                price_code:color.price_code,
                time:time,
                weight:color.weight
              };

            }
          }
        }
      }
      


      


      return stateHelper;
    case types.SET_SELECTED_PRICE_DATA_BY_STATE:
      let result=computeSelectedPriceAndCode(stateHelper.selectedFiles[action.payload.slicerNumber]);
      if(!result)//no material color or quality selected yet
        return stateHelper;
      stateHelper.selectedFiles[action.payload.slicerNumber].selectedPrice=result.price;
      stateHelper.selectedFiles[action.payload.slicerNumber].selectedCode=result.price_code;
      stateHelper.selectedFiles[action.payload.slicerNumber].slicerDetail.weight=result.weight;
      return stateHelper;

    case types.SET_TIME_PRICES_LOADING:
      stateHelper.selectedFiles[action.payload.slicerNumber].timePricesLoading =
        action.payload.value;
      return stateHelper;

    case types.SET_MATERIALS:
      return { ...state, materials: action.payload };

    case types.SET_SELECTED_MATERIAL:
      stateHelper.selectedFiles[action.payload.slicerNumber].selectedMaterial =
        action.payload.value;
      stateHelper.selectedFiles[action.payload.slicerNumber].pricing_cache={};
      return stateHelper;

    case types.SET_SELECTED_MATERIAL_TYPE:
      stateHelper.selectedFiles[action.payload.slicerNumber].selectedMaterialType =
        action.payload.value;
      stateHelper.selectedFiles[action.payload.slicerNumber].pricing_cache={};
      return stateHelper;
    
    case types.SET_SELECTED_COLOR:
      stateHelper.selectedFiles[action.payload.slicerNumber].selectedColor = action.payload.value;
      stateHelper.selectedFiles[action.payload.slicerNumber].pricing_cache={};
      return stateHelper;

    case types.SET_SELECTED_COLOR_CODE:
      stateHelper.selectedFiles[action.payload.slicerNumber].selectedColorCode =
        action.payload.value;
      stateHelper.selectedFiles[action.payload.slicerNumber].pricing_cache={};
      return stateHelper;
    case types.SET_SLICER_DETAIL_WEIGHT:
      stateHelper.selectedFiles[action.payload.slicerNumber].slicerDetail.weight = action.payload.value;
      return stateHelper;
    case types.SET_SCALED_STL_PATH:
      stateHelper.selectedFiles[action.payload.slicerNumber].scaledStlPath = action.payload.value;
      return stateHelper;
    case types.SET_SELECTED_QUALITY:
      stateHelper.selectedFiles[action.payload.slicerNumber].selectedQuality = action.payload.value;
      return stateHelper;

    case types.SET_SELECTED_CODE:
      stateHelper.selectedFiles[action.payload.slicerNumber].selectedCode = action.payload.value;
      return stateHelper;

    case types.SET_SELECTED_PRICE:
      stateHelper.selectedFiles[action.payload.slicerNumber].selectedPrice = action.payload.value;
      return stateHelper;

    case types.SET_SELECTED_DELIVERY_TIME:
      stateHelper.selectedFiles[action.payload.slicerNumber].selectedDeliveryTime =
        action.payload.value;
      return stateHelper;

    case types.SET_QUANTITY:
      stateHelper.selectedFiles[action.payload.slicerNumber].quantity = action.payload.value;
      return stateHelper;

    

    case types.SET_QUALITY_PRICE:
      stateHelper.selectedFiles[action.payload.slicerNumber].qualities[action.payload.qualityId].price = action.payload.value;
      return stateHelper;

    case types.SET_QUALITY_PRICE_LOADING:
      stateHelper.selectedFiles[action.payload.slicerNumber].qualities[action.payload.qualityId].loading =
        action.payload.value;
      return stateHelper;
    case types.SET_QUALITY_WEIGHT:
      stateHelper.selectedFiles[action.payload.slicerNumber].qualities[action.payload.qualityId].weight =
        action.payload.value;
      return stateHelper;
    case types.SET_QUALITY_PRICE_CODE:
      stateHelper.selectedFiles[action.payload.slicerNumber].qualities[action.payload.qualityId].price_code =
        action.payload.value;
      return stateHelper;
    case types.SET_QUALITY_INFILL:
      stateHelper.selectedFiles[action.payload.slicerNumber].qualities[action.payload.qualityId].infill =
        action.payload.value;
      return stateHelper;
    case types.SET_PRICING_CACHE_ENTRY:
      if(!stateHelper.selectedFiles[action.payload.slicerNumber].pricing_cache[action.payload.qualityId])
        stateHelper.selectedFiles[action.payload.slicerNumber].pricing_cache[action.payload.qualityId]={};
      stateHelper.selectedFiles[action.payload.slicerNumber].pricing_cache[action.payload.qualityId][action.payload.infill] = 
          {
            price:action.payload.price,
            price_code:action.payload.price_code,
            time:action.payload.time,
            weight:action.payload.weight
          };
      return stateHelper;
    case types.PERCENT_COMPLETED:
      stateHelper.selectedFiles[action.payload.slicerNumber].percentCompleted =
        action.payload.value;
      return stateHelper;

    case types.SET_STL_BLOB:
      stateHelper.selectedFiles[action.payload.slicerNumber].stlBlob = action.payload.value;
      return stateHelper;

    case types.SET_SLICER_DETAIL_VOLUME:
      stateHelper.selectedFiles[action.payload.slicerNumber].slicerDetail.volume =
        action.payload.value;
      return stateHelper;

    case types.SET_SLICER_DETAIL_BOUNDINGBOX:
      stateHelper.selectedFiles[action.payload.slicerNumber].slicerDetail.boundingBox =
        action.payload.value;
      return stateHelper;

    case types.SET_SLICER_DETAIL_DENSITY:
      stateHelper.selectedFiles[action.payload.slicerNumber].slicerDetail.density =
        action.payload.value;
      return stateHelper;

    case types.SET_SLICER_SELECTED_COLOR_OBJECT:
      stateHelper.selectedFiles[action.payload.slicerNumber].selectedColorObject =
        action.payload.value;
      return stateHelper;

    case types.SET_QUALITY_DETAIL_TIME:
      stateHelper.selectedFiles[action.payload.slicerNumber].qualities[action.payload.qualityId].time =
        action.payload.value;
      return stateHelper;

    

    

    case types.SET_SELECTED_FILES:
      return { ...state, selectedFiles: action.payload };
    case types.SET_MODEL_EXTENSION:
      stateHelper.selectedFiles[action.payload.slicerNumber].modelExtension = action.payload.value;
      return stateHelper;
    

    case types.INITIALIZE_SLICER:
      return {
        ...state,
        files: [],
        materials: [],
        selectedFiles: [
          {
            slicerId: 'a' + Math.random(),
            scaleXInitial: 0,
            scaleX: 0,
            scaleY: 0,
            scaleZ: 0,
            selectedStl: '',
            selectedStep: 1,
            volumePrices: [],
            volumePricesLoading: false,
            timePrices: [],
            timePricesLoading: false,
            selectedMaterial: '',
            selectedMaterialType: '',
            selectedColor: 'rgb(0,0,0)',
            selectedColorCode: '',
            selectedQuality: 1,
            selectedCode: '',
            selectedPrice: 0,
            selectedDeliveryTime: 0,
            quantity: 1,
            pricing_cache:{},
            modelExtension:'',
            qualities:{
              "1":{
                price:0,
                price_code:'',
                loading:false,
                infill:20,
                time:0,
                weight:0
              },
              "2":{
                price:0,
                price_code:'',
                loading:false,
                infill:20,
                time:0,
                weight:0
              },
              "3":{
                price:0,
                price_code:'',
                loading:false,
                infill:20,
                time:0,
                weight:0
              }
              
            },
            
            percentCompleted: -1,
            stlBlob: '',
            scaledStlPath:'',
            slicerDetail: {
              volume: 0,
              boundingBox: 0,
              density: 0,
              weight:0
            },
          },
        ],
      };
    default:
      return state;
  }
};

export const key = 'slicer';
export default reducer;
