import { types } from './actions';

const reducer = (state, action) => {
  switch (action.type) {
    case types.GET_USERS:
      return { ...state, users: action.payload };
    case types.TOTAL_USERS:
      return { ...state, totalUsers: action.payload };
    case types.USER_PROFILE:
      return { ...state, userProfile: action.payload };
    case types.USER_CREDIT:
      return { ...state, userCredit: action.payload };
    case types.USER_FILES:
      return { ...state, userFiles: action.payload };
    case types.USER_INVOICES:
      return { ...state, userInvoices: action.payload };
    default:
      return state;
  }
};

export const key = 'users';
export default reducer;
