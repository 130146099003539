import { types } from './actions';

const reducer = (state, action) => {
  switch (action.type) {
    case types.GET_DISCOUNTS:
      return { ...state, discounts: action.payload };
    case types.SET_TOTAL_DISCOUNTS:
      return { ...state, totalDiscounts: action.payload };
    case types.GET_MATERIALS:
      return { ...state, materials: action.payload };
    default:
      return state;
  }
};

export const key = 'discounts';
export default reducer;
