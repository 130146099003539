import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext } from 'context/themeContext';
import LoadingBlueSVG from 'asset/loading-blue.svg';

const LazyLoadingIcon = styled.img.attrs({
  src: LoadingBlueSVG,
})`
  width: 7.079vw;
  height: 7.079vw;
`;

const LazyLoadingWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.width ? props.width : '100vw')};
  height: 100vh;
  background-color: ${(props) => (props.theme.primaryBackground)};
  display: ${(props) => (props.display ? 'flex' : 'none')};
`;

export const LazyLoadingComponent = (props) => {
  const { theme, darkThemeSelected } = useContext(ThemeContext);
  return (
    <LazyLoadingWrapper display={props.display} theme={theme} width={props.width}>
      <LazyLoadingIcon />
    </LazyLoadingWrapper>
  )
}


export const Wrapper = styled.div`
  position: relative;
  height: 52vw;
  overflow-y:auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

export const HeightSpace = styled.div`
  width: 100%;
  height: ${(props) => props.height};
`;

export const StyledSpan = styled.span`
  color: ${(props) => props.theme.blueColorPrimary};
  &:hover {
    color: ${(props) => props.theme.blueColorPrimaryHover};
  }
  margin-left: 0.3vw;
  margin-right: 0.3vw;
`;

export const AutenticatedWrapper = styled.div`
  position: absolute;
  top: 0vw;
  left: ${(props) => (props.left === -1 ? '-10vw' : props.left === 1 ? '30vw' : '11.97vw')};
  opacity: ${(props) => (props.left === -1 ? '0' : props.left === 1 ? '0' : '1')};
  visibility: ${(props) =>
    props.left === -1 ? 'hidden' : props.left === 1 ? 'hidden' : 'visible'};
  height: calc(100vh - 4.008vw);
  width: calc(100vw - 11.97vw);
  box-sizing: border-box;
  transition: 300ms ease-in-out, opacity 300ms ease-in-out;
  padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : '0')};
`;

export const ComponentsWrapper = styled.div`
  position: relative;
  height: ${(props) => props.height};
  width: calc(100% - 0.52vw);
  box-sizing: border-box;
  margin-top: ${(props) => props.marginTop && props.marginTop + 'vw'};
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 1vw;
    border-radius: 1vw;
    background-color: ${(props) => props.theme.SecondaryBackground};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 0.26vw ${(props) => props.theme.SecondaryBackground};  */
    border-radius: 1vw;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.borderBackground};
    border: 0vw solid #f4f4f4;
    border-radius: 1vw;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.tagBorder};
  }
`;

export const SingleComponent = styled.div`
  position: relative;
  height: ${(props) => (props.size === 'accordion-size' ? '4.06vw' : '4.164vw')};
  width: ${(props) =>
    props.size === 'color-size'
      ? '84.413vw'
      : props.size === 'accordion-size'
        ? '62.62372vw'
        : props.size === 'small'
          ? '72vw'
          : '84.903vw'};
  border-radius: 4.164vw;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-left: ${(props) =>
    props.size === 'color-size'
      ? '0vw'
      : props.size === 'accordion-size'
        ? props.paymentType
          ? '0'
          : '-1vw'
        : '1.04vw'};
  margin-bottom: 1.04vw;
  background-color: ${(props) =>
    props.selected ? props.theme.cardBackground : props.theme.cardBackground + '00'};
  cursor: pointer;
  &:hover {
    background-color: ${(props) =>
    props.size === 'accordion-size'
      ? props.theme.cardOnCardBackground
      : props.theme.cardBackground};
  }
  transition: 200ms linear;
`;

export const SingleClickWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: ${(props) => (props.size === 'accordion-size' ? '4.06vw' : '4.164vw')};
  width: ${(props) =>
    props.size === 'color-size'
      ? '84.413vw'
      : props.size === 'accordion-size'
        ? '62.62372vw'
        : props.size === 'small'
          ? '72vw'
          : '84.903vw'};
  border-radius: 4.164vw;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ElementWrapper = styled.div`
  width: 0vw;
  color: ${(props) => props.theme.textPrimary};
  font-weight: ${(props) => props.fontWeight && props.fontWeight};
  font-size: 0.937vw;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.align ? props.align : 'center')};
  white-space: nowrap;
`;

export const Circle = styled.div`
  width: ${(props) => (props.size ? props.size : '2.394vw')};
  height: ${(props) => (props.size ? props.size : '2.394vw')};
  background-color: ${(props) => props.colorCode};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.align ? props.align : 'center')};
  overflow: hidden;
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`;

export const Space = styled.div`
  width: ${(props) => props.width + 'vw'};
`;

export const NotificationCircle = styled.div`
  position: relative;
  top: ${(props) => props.top};
  left: 1.04vw;
  height: ${(props) => props.width};
  width: ${(props) => props.width};
  border-radius: 50%;
  background-color: ${(props) => props.theme.baraRedColor};
  color: ${(props) => props.theme.textPrimary};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '0.56vw')};
  overflow: hidden;
`;

const StyledSecondNav = styled.div`
  position: relative;
  height: 4.893vw;
  width: calc(100vw - 11.97vw);
  border-bottom: 1px solid ${(props) => props.theme.borderBackground};
  padding-right: 1.561vw;
  padding-left: 1.561vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
`;

export const SecondNav = (props) => {
  const { theme } = useContext(ThemeContext);

  return <StyledSecondNav theme={theme}>{props.children}</StyledSecondNav>;
};
