import { types } from './actions';

const reducer = (state, action) => {
  switch (action.type) {
    case types.SET_MATERIALS:
      return { ...state, materials: action.payload };
    case types.SET_MATERIAL_IMAGE_LOADING:
      return { ...state, materialImageLoading: action.payload };
    default:
      return state;
  }
};

export const key = 'materials';
export default reducer;
