export const types = {
  LOGIN_STATE: 'LOGIN_STATE',
  SELECTED_HUB_MODE_ID: 'SELECTED_HUB_MODE_ID',
  SELECTED_HUB_MODE_TYPE: 'SELECTED_HUB_MODE_TYPE',
  SET_PROFILE: 'SET_PROFILE',
  SET_TOKEN: 'SET_TOKEN',
  SET_SOCKET: 'SET_SOCKET',
  SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
  SET_EMAIL: 'SET_EMAIL',
  SET_REDIRECT: 'SET_REDIRECT',
  SET_ROLES: 'SET_ROLES',
  SET_SELECTED_ROLES: 'SET_SELECTED_ROLES',
  SET_FAMILY_SIZE: 'SET_FAMILY_SIZE',
  SET_USED_SIZE: 'SET_USED_SIZE',
  SET_FAMILY_CURRENCY: 'SET_FAMILY_CURRENCY',
  SET_FAMILY_TYPE: 'SET_FAMILY_TYPE',
  NEW_NOTIFICATION_RECIEVED:'NEW_NOTIFICATION_RECIEVED'
};

const actions = {
  setLoginState: (payload) => ({
    type: types.LOGIN_STATE,
    payload,
  }),
  setSelectedHUbModeId: (payload) => ({
    type: types.SELECTED_HUB_MODE_ID,
    payload,
  }),
  setSelectedHUbModeType: (payload) => ({
    type: types.SELECTED_HUB_MODE_TYPE,
    payload,
  }),
  setProfile: (payload) => ({
    type: types.SET_PROFILE,
    payload,
  }),
  setToken: (payload) => ({
    type: types.SET_TOKEN,
    payload,
  }),
  setSocket: (payload) => ({
    type: types.SET_SOCKET,
    payload,
  }),
  setNotifications: (payload) => ({
    type: types.SET_NOTIFICATIONS,
    payload,
  }),
  newNotificationReceived: (payload) => ({
    type: types.NEW_NOTIFICATION_RECIEVED,
    payload,
  }),
  setEmail: (payload) => ({
    type: types.SET_EMAIL,
    payload,
  }),
  setRedirect: (payload) => ({
    type: types.SET_REDIRECT,
    payload,
  }),
  setRoles: (payload) => ({
    type: types.SET_ROLES,
    payload,
  }),
  SetSelectedRoles: (payload) => ({
    type: types.SET_SELECTED_ROLES,
    payload,
  }),
  setFamilySize: (payload) => ({
    type: types.SET_FAMILY_SIZE,
    payload,
  }),
  setUsedSize: (payload) => ({
    type: types.SET_USED_SIZE,
    payload,
  }),
  setFamilyCurrency: (payload) => ({
    type: types.SET_FAMILY_CURRENCY,
    payload,
  }),
  setFamilyType: (payload) => ({
    type: types.SET_FAMILY_TYPE,
    payload,
  }),
};

export default actions;
