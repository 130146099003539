import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext } from 'context/themeContext';
import { CounterContext } from 'context/bestContext';
import { Link } from 'react-router-dom';
import { NotificationCircle } from 'components/wrapper';

const Wrapper = styled.div`
  position: relative;
  padding-left: 2.342vw;
  height: 2.186vw;
  width: 10.046vw;
  font-size: 0.937vw;
  font-weight: 500;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  a {
    color: ${(props) => (props.display ? props.theme.textPrimary : props.theme.textSecondary)};
  }
  transition: 200ms linear;
`;

const Circle = styled.div`
  position: absolute;
  left: 1.04vw;
  height: 0.832vw;
  width: ${(props) => (props.display ? '0.832vw' : '0')};
  border-radius: 50%;
  background-color: ${(props) => props.theme.textPrimary};
  transition: 200ms linear;
`;

const Tab = (props) => {
  const { theme } = useContext(ThemeContext);
  const { setNextHistory } = useContext(CounterContext);
  const onClick = () => {
    setNextHistory([]);
  };
  return (
    <Wrapper theme={theme} display={props.selectedTab === props.link}>
      <Circle theme={theme} display={props.selectedTab === props.link} />
      <Link onClick={onClick} style={{ textDecoration: 'none' }} to={props.link}>
        {props.name}
      </Link>
      {props.notifications ? (
        <NotificationCircle width="1.2vw" theme={theme}>
          {props.notifications}
        </NotificationCircle>
      ) : null}
    </Wrapper>
  );
};

export default Tab;
