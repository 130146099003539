import actions from './actions';

const usersPerformances = (dispatch) => {
  const setBoardingState = async (data) => {
    dispatch(actions.setBoardingState(data));
  };
  const setBoardingOpen = async (data) => {
    dispatch(actions.setBoardingOpen(data));
  };

  const setBoardingFirstLaunch = async (data) => {
    dispatch(actions.setBoardingFirstLaunch(data));
  };

  return {
    setBoardingState,
    setBoardingOpen,
    setBoardingFirstLaunch,
  };
};

export default usersPerformances;
