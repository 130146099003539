import React, { useContext } from 'react';
import styled from 'styled-components';
import { CounterContext } from 'context/bestContext';
import { useHistory, Link } from 'react-router-dom';
import NextSVG from 'asset/next.svg';
import { ThemeContext } from 'context/themeContext';
import MainContext from 'MainContext';
import FablogBrandSVG from 'asset/fablog-brand.svg';
import { StyledImage } from 'components/wrapper';
import Profile from 'asset/profile.svg';

const FablogBrand = styled.img.attrs({
  src: FablogBrandSVG,
})`
  width: 9.436vw;
  height: 9.436vw;
  margin-left: 1.192vw;
  display: block;
  cursor: pointer;
  filter: ${(props) =>
    props.darkThemeSelected
      ? 'invert(100%) sepia(100%) saturate(0%) hue-rotate(354deg) brightness(103%) contrast(103%)'
      : ''};
`;

const NextIcon = styled.img.attrs({
  src: NextSVG,
})`
  width: 0.884vw;
  height: 0.884vw;
  filter: ${(props) =>
    props.active
      ? props.darkThemeSelected
        ? 'invert(100%) sepia(100%) saturate(0%) hue-rotate(21deg) brightness(103%) contrast(101%)'
        : 'unset'
      : 'invert(76%) sepia(3%) saturate(22%) hue-rotate(321deg) brightness(81%) contrast(81%)'};
  transition: 200ms linear;
`;

const BackIcon = styled.img.attrs({
  src: NextSVG,
})`
  width: 0.884vw;
  height: 0.884vw;
  transform: scaleX(-1);
  filter: ${(props) =>
    props.active
      ? props.darkThemeSelected
        ? 'invert(100%) sepia(100%) saturate(0%) hue-rotate(21deg) brightness(103%) contrast(101%)'
        : 'unset'
      : 'invert(76%) sepia(3%) saturate(22%) hue-rotate(321deg) brightness(81%) contrast(81%)'};
  transition: 200ms linear;
`;

const NavWrapper = styled.div`
  position: fixed;
  width: ${(props) => (props.athenticated ? 'calc(100vw - 11.97vw)' : '100vw')};
  height: 4.008vw;
  top: 0;
  // top: ${(props) => (props.loginStatus ? '3vw' : '0')};  //the top is changed by qazal for Dev ERROR
  right: 0;
  box-sizing: border-box;
  color: ${(props) => props.theme.textPrimary};
  display: ${(props) => (props.loginStatus ? 'flex' : 'none')};
  justify-content: ${(props) => (props.athenticated ? 'space-between' : 'flex-start')};
  align-items: center;
`;

const LeftAndRightWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PageName = styled.div`
  cursor: default;
  font-size: 1.249vw;
  font-weight: bold;
  color: ${(props) => props.theme.textPrimary};
  margin-left: 1.56vw;
`;

const NavigationElement = styled.div`
  font-size: 1.249vw;
  font-weight: bold;
  color: ${(props) => props.theme.textSecondary};
  &:hover {
    color: ${(props) => props.theme.textPrimary};
  }
  margin-left: 1.56vw;
`;

const StyledSlash = styled.div`
  font-size: 1.249vw;
  font-weight: bold;
  color: ${(props) => props.theme.textSecondary};
  margin-left: 1.56vw;
`;


const Circle = styled.div`
  width: 2.342vw;
  height: 2.342vw;
  margin-right: 1.04vw;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 300ms linear;
  background-color: ${(props) => props.theme.borderBackground};
`;

const VerticalLine = styled.div`
  width: 1px;
  height: 1.707vw;
  margin-left: 1.681vw;
  margin-right: 1.535vw;
  background-color: ${(props) => props.theme.borderBackground};
`;

const DefaultProfile = styled.img.attrs({
  src: Profile,
})`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`;

const BackOffice = styled.div`
  color: ${(props) => props.theme.textPrimary};
  font-size: 1.249vw;
  font-weight: bold;
`;
const ImageWrapper = styled.div`
  width: 1.613vw;
  height: 1.613vw;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.textPrimary};
  cursor: ${(props) => (props.active ? 'pointer' : 'not-allowed')};
  transition: 300ms linear;
  &:hover {
    background-color: ${(props) => props.active && props.theme.borderBackground};
  }
`;
const ImageWrapperLeft = styled(ImageWrapper)`
  margin-left: 0.937vw;
`;

const ImageWrapperRight = styled(ImageWrapper)`
  margin-left: 0.52vw;
`;
function NavBar() {
  const { darkThemeSelected, theme } = useContext(ThemeContext);
  const {
    linkName,
    setLlinkName,
    history: contextHistory,
    setHistory,
    setSelectedTab,
    nextHistory,
    setNextHistory,
    navigationBar,
    setShowDrawer,
  } = useContext(CounterContext);
  const { token, profile } = useContext(MainContext).login;
  let history = useHistory();
  const onBackClick = async () => {
    if (contextHistory.length > 1) {
      let helper = [...contextHistory];
      let nextHelper = [...nextHistory];
      nextHelper.push(contextHistory[helper.length - 1]);
      let redirectHelper = contextHistory[helper.length - 2];
      await helper.splice(helper.length - 2, 2);
      setNextHistory(nextHelper);
      setHistory(helper);
      console.log(nextHelper);
      history.push(redirectHelper);
    }
  };
  const onNextClick = async () => {
    if (nextHistory.length > 0) {
      let nextHelper = [...nextHistory];
      nextHelper.splice(nextHelper.length - 1);
      let redirectHelper = nextHistory[nextHistory.length - 1];
      setNextHistory(nextHelper);
      history.push(redirectHelper);
    }
  };
  let navigationArray = [];
  navigationBar.map((navigationElement) => {
    navigationArray.push(
      <>
        <Link to={navigationElement.link} style={{ textDecoration: 'none' }}>
          <NavigationElement theme={theme}>{navigationElement.name + ' '}</NavigationElement>
        </Link>
        <StyledSlash theme={theme}>/</StyledSlash>
      </>
    );
  });
  return token.length > 0 ? (
    <NavWrapper loginStatus={token.length > 0} athenticated={linkName.length > 0}>
      <LeftAndRightWrapper theme={theme}>
        <ImageWrapperLeft theme={theme} active={contextHistory.length > 1} onClick={onBackClick}>
          <BackIcon active={contextHistory.length > 1} darkThemeSelected={darkThemeSelected} />
        </ImageWrapperLeft>
        <ImageWrapperRight theme={theme} active={nextHistory.length > 0} onClick={onNextClick}>
          <NextIcon active={nextHistory.length > 0} darkThemeSelected={darkThemeSelected} />
        </ImageWrapperRight>
        {navigationArray}
        <PageName theme={theme}>{linkName}</PageName>
      </LeftAndRightWrapper>
      <LeftAndRightWrapper>
        <Circle theme={theme} onClick={() => setShowDrawer(true)}>
          {profile.profile_picture ? (
            <StyledImage
              src={`${process.env.REACT_APP_STATISTIC_SERVICE_URL}/avatar/${profile.profile_picture}`}
            />
          ): <DefaultProfile />}
        </Circle>
      </LeftAndRightWrapper>
    </NavWrapper>
  ) : (
    <NavWrapper loginStatus={true} athenticated={false}>
      <a href="https://layers.app" target="_blank" rel="noreferrer">
        <FablogBrand darkThemeSelected={darkThemeSelected} />
      </a>
      <VerticalLine theme={theme} />
      <BackOffice theme={theme}>Back Office</BackOffice>
    </NavWrapper>
  );
}

export default React.memo(NavBar);
