import React, { useContext, useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import MainContext from 'MainContext';
import { checkPermission } from 'utils/permisstion';

const PermisstionCheck = (props) => {
  const { roles, selectedRole, selectedHUbModeId } = useContext(MainContext).login;

  const [allowed, setAllowed] = useState(true);

  const { redirect: redirectProps, access, isHubAlload } = props;

  useEffect(() => {
    if (!(selectedRole === '' || checkPermission({ access, selectedRole, roles }))) {
      if (!isHubAlload || !selectedHUbModeId) {
        setAllowed(false);
      }
    }
    return () => {
      setAllowed(true);
    };
  }, [selectedRole, access]);

  return allowed === true ? props.children : <Redirect to={redirectProps} />;
};

export default PermisstionCheck;
