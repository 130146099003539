import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { CounterContext } from 'context/bestContext';
import MainContext from 'MainContext';
import { ThemeContext } from 'context/themeContext';
import Tab from './components/tab';
import Upgrade from './components/upgrade';
import DropDown from 'components/drop-down/family-selector';
import { checkPermission, getSelectedRoleIndex } from 'utils/permisstion';
import axios from 'axios';
import FablogBrandSVG from 'asset/fablog-brand.svg';
import { updateUserProfile } from 'containers/login/container/api';


const FablogBrand = styled.img.attrs({
  src: FablogBrandSVG,
})`
  width: 9.436vw;
  height: 9.436vw;
  display: block;
  margin: auto;
  cursor: pointer;
  margin-top: -2.7vw;
  margin-bottom: -2.5vw;
  filter: ${(props) =>
    props.darkThemeSelected
      ? 'invert(100%) sepia(100%) saturate(0%) hue-rotate(354deg) brightness(103%) contrast(103%)'
      : ''};
`;

const SideWrapper = styled.div`
  position: fixed;
  width: 11.97vw;
  height: 100vh;
  top: 0;
  left: ${(props) => (props.displaySidBar ? '0' : '-11.97vw')};
  z-index: 2;
  transition: left 0.4s ease-in-out;
  background-color: ${(props) => props.theme.cardBackground};
`;

const StyledAWrapper = styled.div`
  border-top: 1px solid ${(props) => props.theme.borderBackground};
  position: relative;
  width: 100%;
  height: 3.281vw;
  transition: 0.4s ease-in-out;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-top: 0.7vw;
  padding-left: 2.291vw;
  a {
    font-size: 0.937vw;
    text-decoration: none;
    color: ${(props) => props.theme.textSecondary};
    &:hover {
      color: ${(props) => props.theme.textPrimary};
    }
  }
`;

function Sidebar() {
  const { showSidBar, selectedTab } = useContext(CounterContext);
  const {
    token,
    setSelectedRoleContext,
    roles,
    selectedRole,
    profile,
    notifications,
    selectedHUbModeId,
    selectedHUbModeType,
  } = useContext(MainContext).login;
  const { theme, darkThemeSelected } = useContext(ThemeContext);
  let history = useHistory();

  let options = [{ name: 'Your business', id: 0 }];
  roles.map((role, index) => {
    options.push({ name: role.family, id: index + 1 });
  });
  const setSelectedRole = async (id) => {
    if (id === 0) {
      axios.defaults.headers.common.family = profile.cellphone;
      await updateUserProfile({ defualt_family: 'self' });
      setSelectedRoleContext('');
    } else {
      axios.defaults.headers.common.family = roles[id - 1].family;
      await updateUserProfile({ defualt_family: roles[id - 1].family });
      setSelectedRoleContext(roles[id - 1].family);
    }
    history.push('/dashboard');
  };
  const selectedRoleIndex = getSelectedRoleIndex({ roles, selectedRole });
  const onLogoClick = () => {
    history.push('/dashboard');
  };
  return (
    <SideWrapper displaySidBar={showSidBar && token.length > 0} theme={theme}>
      <FablogBrand darkThemeSelected={darkThemeSelected} onClick={onLogoClick} />
      {roles.length !== 0 && (
        <DropDown
          options={options}
          selected={selectedRoleIndex}
          setSelected={setSelectedRole}
          label="select-family"
        />
      )}
      {!selectedHUbModeId ? (
        <>
          <Tab id={1} selectedTab={selectedTab} link="/dashboard" name="Dashboard" />
          {(selectedRole === '' ||
            checkPermission({ access: 'get-invoices', selectedRole, roles })) && (
            <Tab
              id={2}
              selectedTab={selectedTab}
              notifications={notifications.length}
              link="/orders"
              name="Orders"
            />
          )}
          {(selectedRole === '' ||
            checkPermission({ access: 'get-invoices', selectedRole, roles })) && (
            <Tab
              id={3}
              selectedTab={selectedTab}
              notifications={0}
              link="/conversations"
              name="Conversations"
            />
          )}
          {(selectedRole === '' ||
            checkPermission({ access: 'get-discounts', selectedRole, roles })) && (
            <Tab id={3} selectedTab={selectedTab} link="/discounts" name="Discounts" />
          )}
          {(selectedRole === '' ||
            checkPermission({ access: 'get-hubs', selectedRole, roles })) && (
            <Tab id={4} selectedTab={selectedTab} link="/workshops" name="Workshops" />
          )}
          {(selectedRole === '' ||
            checkPermission({ access: 'get-materials', selectedRole, roles })) && (
            <Tab
              id={5}
              selectedTab={selectedTab}
              link="/tech-and-materials"
              name="Tech & Materials"
            />
          )}
          {/* {(selectedRole === '' || checkPermission({ access: 'get-printers', selectedRole, roles })) && <Tab
                id={5}
                selectedTab={selectedTab}
                link='/printers'
                name='Printers'
            />} */}
          {(selectedRole === '' ||
            checkPermission({ access: 'get-users', selectedRole, roles })) && (
            <Tab id={7} selectedTab={selectedTab} link="/users" name="Users" />
          )}
          {selectedRole === '' && (
            <Tab id={8} selectedTab={selectedTab} link="/settings/general" name="Settings" />
          )}
          {selectedRole === '' && profile.business_name && (
            <StyledAWrapper theme={theme}>
              <a
                target="_blank"
                href={
                  profile.business_name.includes('.')
                    ? `https://${profile.business_name}`
                    : `https://${profile.business_name}.3dlayers.app`
                }
                rel="noreferrer"
              >
                Visit my shop
              </a>
            </StyledAWrapper>
          )}
          <Upgrade profile={profile}/>
        </>
      ) : (
        <>
          {/* <Tab id={1} selectedTab={selectedTab} link="/dashboard" name="Dashboard" /> */}
          <Tab
            id={2}
            selectedTab={selectedTab}
            notifications={notifications.length}
            link="/orders"
            name="Orders"
          />
          {selectedHUbModeType === 'manager' && (
            <Tab id={8} selectedTab={selectedTab} link="/settings/general" name="Settings" />
          )}
        </>
      )}
    </SideWrapper>
  );
}

export default Sidebar;
