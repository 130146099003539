import { types } from './actions';

const reducer = (state, action) => {
  switch (action.type) {
    case types.SET_PERMISSIONS:
      return { ...state, permissions: action.payload };
    case types.SETTING_SET_ROLES:
      return { ...state, roles: action.payload };
    case types.UPDATE_ROLES:
      return { ...state, roles: [...state.roles, ...action.payload] };
    case types.TOTAL_ROLES:
      return { ...state, totalRoles: action.payload };
    case types.SET_PAYMENT_METHODS:
      return { ...state, paymentMethods: action.payload };
    case types.SET_PAYMENT_METHOD_TYPES:
      return { ...state, paymentMethodTypes: action.payload };
    case types.SET_COUNTRIES:
      return { ...state, countries: action.payload };
    case types.SET_LOCATIONS:
      return { ...state, locations: action.payload };
    case types.SET_SHIPPING:
      return { ...state, shippings: action.payload };
    case types.SET_TAXES:
      return { ...state, taxes: action.payload };
    default:
      return state;
  }
};

export const key = 'setting';
export default reducer;
