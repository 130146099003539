export const types = {
  GET_USERS: 'GET_USERS',
  TOTAL_USERS: 'TOTAL_USERS',
  USER_PROFILE: 'USER_PROFILE',
  USER_CREDIT: 'USER_CREDIT',
  USER_FILES: 'USER_FILES',
  USER_INVOICES: 'USER_INVOICES',
};

const actions = {
  setUsers: (payload) => ({
    type: types.GET_USERS,
    payload,
  }),
  setTotalUsers: (payload) => ({
    type: types.TOTAL_USERS,
    payload,
  }),
  setUserProfile: (payload) => ({
    type: types.USER_PROFILE,
    payload,
  }),
  setUserCredit: (payload) => ({
    type: types.USER_CREDIT,
    payload,
  }),
  setUserFiles: (payload) => ({
    type: types.USER_FILES,
    payload,
  }),
  setUserInvoices: (payload) => ({
    type: types.USER_INVOICES,
    payload,
  }),
};

export default actions;
