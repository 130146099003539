import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext } from 'context/themeContext';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 39.979vw;
  height: 39.823vw;
  opacity: ${(props) => (props.display ? '1' : '0')};
  visibility: ${(props) => (props.display ? 'visible' : 'hidden')};
  transition: 200ms ease-in-out;
  font-size: 7.6vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.textPrimary};
`;

const Step1 = (props) => {
  const { theme } = useContext(ThemeContext);
  const { display, setBoardingFirstLaunch, boardingOpen } = props;

  useEffect(() => {
    if (boardingOpen) {
      setBoardingFirstLaunchFunc(false);
    }
  }, [boardingOpen]);

  const setBoardingFirstLaunchFunc = () => {
    setTimeout(() => {
      setBoardingFirstLaunch(false);
    }, 2000);
  };

  return (
    <Wrapper display={display} theme={theme}>
      Hello.
    </Wrapper>
  );
};

export default Step1;
