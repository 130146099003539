import styled from 'styled-components';
import Step1SVG from 'asset/onboarding/01.svg';
import Step2SVG from 'asset/onboarding/02.svg';
import Step3SVG from 'asset/onboarding/03.svg';
import Step4SVG from 'asset/onboarding/04.svg';
import Step5SVG from 'asset/onboarding/05.svg';
import Step6SVG from 'asset/onboarding/06.svg';

export const Step1Icon = styled.img.attrs({
  src: Step1SVG,
})`
  width: 32.4vw;
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: -1.56vw 1.04vw 1.56vw 0 rgba(0, 0, 0, 0.3);
  border-top-left-radius: 2.08vw;
  border-top-right-radius: 2.08vw;
`;

export const Step2Icon = styled.img.attrs({
  src: Step2SVG,
})`
  width: 26.028vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Step3Icon = styled.img.attrs({
  src: Step3SVG,
})`
  width: 22.904vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Step4Icon = styled.img.attrs({
  src: Step4SVG,
})`
  width: 22.904vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Step5Icon = styled.img.attrs({
  src: Step5SVG,
})`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Step6Icon = styled.img.attrs({
  src: Step6SVG,
})`
  height: 17.49vw;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 39.979vw;
  height: 39.823vw;
  opacity: ${(props) => (props.display ? '1' : '0')};
  visibility: ${(props) => (props.display ? 'visible' : 'hidden')};
  transition: 200ms ease-in-out;
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 39.979vw;
  height: 19.052vw;
  overflow: hidden;
  background-color: ${(props) => props.theme.redColorSecondary};
`;

export const Content = styled.div`
  position: relative;
  width: 39.979vw;
  box-sizing: border-box;
  padding-top: 2.082vw;
  padding-left: 6.871vw;
  padding-right: 6.871vw;
`;

export const Title = styled.div`
  font-size: 1.249vw;
  font-weight: bold;
  color: ${(props) => props.theme.textPrimary};
`;

export const Text = styled.div`
  font-size: 0.832vw;
  margin-top: 1.04vw;
  color: ${(props) => props.theme.textSecondary};
`;

export const Inputs = styled.div`
  margin-top: 2.238vw;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const SiteLink = styled.div`
  margin-top: 2.602vw;
  display: flex;
  justify-content: center;
  width: 100%;
  color: ${(props) => props.theme.textPrimary};
  font-size: 1.769vw;
  a {
    color: ${(props) => props.theme.textPrimary};
  }
`;
