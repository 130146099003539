import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext } from 'context/themeContext';
import MoreSVG from 'asset/more.svg';
import EditSVG from 'asset/order/pen.svg';
import LoadingWhiteSVG from 'asset/loading-white.svg';

const More = styled.img.attrs({
  src: MoreSVG,
})`
  position: relative;
  width: ${(props) =>
    props.size === 'larg' ? '2.274vw' : props.size === 'small' ? '0.728vw' : '1.3vw'};
  height: ${(props) =>
    props.size === 'larg' ? '2.274vw' : props.size === 'small' ? '0.728vw' : '1.3vw'};
  margin-right: ${(props) =>
    props.size === 'larg' ? '0.978vw' : props.size === 'small' ? '0.505vw' : '0.624vw'};
  transition: 200ms linear;
  filter: ${(props) =>
      !props.darkThemeSelected && props.type =='secondary'
        ? 'invert(75%) sepia(5%) saturate(2%) hue-rotate(21deg) brightness(95%) contrast(85%)'
        : 'unset'
  }

`;

const Edit = styled.img.attrs({
  src: EditSVG,
})`
  position: relative;
  width: ${(props) =>
    props.size === 'larg' ? '2.274vw' : props.size === 'small' ? '0.728vw' : '1.3vw'};
  height: ${(props) =>
    props.size === 'larg' ? '2.274vw' : props.size === 'small' ? '0.728vw' : '1.3vw'};
  margin-right: ${(props) =>
    props.size === 'larg' ? '0.978vw' : props.size === 'small' ? '0.505vw' : '0.624vw'};
  transition: 200ms linear;
`;

const TextWrapper=styled.div`
margin-right:0.8vw;
`;

const StyledButton = styled.div`
  margin-top: ${(props) => (props.marginTop ? props.marginTop + 'vw' : '0')};
  font-size: ${(props) =>
    props.size === 'larg' ? '1.4vw' : props.size === 'small' ? '0.624vw' : '0.937vw'};
  transition: 0.4s;
  border: none;
  display: flex;
  width: fit-content;
  align-items: center;
  padding: ${(props) =>
    props.size === 'larg'
      ? '0.989vw 1.405vw'
      : props.size === 'small'
        ? '0.416vw 0.624vw'
        : '0.572vw 0.832vw'};
  border-radius: ${(props) =>
    props.size === 'larg' ? '1.874vw' : props.size === 'small' ? '0.937vw' : '1.197vw'};
  color: ${(props) =>
    props.deActive
      ? props.theme.textDisabled
      : props.type === 'secondary'
        ? props.theme.textPrimary
        :props.type === 'white'
        ? 'black'
        : 'white'};
  background-color: ${(props) =>
    props.deActive
      ? props.theme.inputBackground
      : props.type === 'secondary'
        ? 'unset'
        : props.type === 'white'
        ? 'white'
        : props.theme.blueColorPrimary};
  &:hover {
    &:not(:disabled) {
      cursor: ${(props) => (props.deActive ? 'not-allowed' : 'pointer')};
      background-color: ${(props) =>
    props.deActive || props.type === 'secondary'
      ? props.theme.inputBackground
      : props.theme.blueColorPrimaryHover};
      color: ${(props) =>
    props.deActive
      ? props.theme.textDisabled
      : props.type === 'secondary'
        ? props.theme.textPrimary
        : 'white'};
    }
  }
`;

const LoadingWhiteIcon = styled.img.attrs({
  src: LoadingWhiteSVG,
})`
  width: 1.2w;
  height: 1.2vw;
  margin-left: 0.5vw;
`;

const Button = (props) => {
  const { theme, darkThemeSelected } = useContext(ThemeContext);
  const { type, size, deActive, image, hasOrder, showLoading } = props;
  return (
    <StyledButton
      theme={theme}
      size={size}
      type={type}
      deActive={deActive}
      marginTop={props.marginTop}
      onClick={deActive ? undefined : props.onClick}
      backgroundColor={props.backgroundColor}
    >
      {
        hasOrder ?
          <>
          {<Edit darkThemeSelected={darkThemeSelected}/>}
            <TextWrapper>
              {props.text}
            </TextWrapper>
            {image && <More darkThemeSelected={darkThemeSelected} type={type}/>}
          </>
          :
          <>
          {image && <More darkThemeSelected={darkThemeSelected} />}
          {props.text}
          {showLoading && <LoadingWhiteIcon/>}
          </>
      }
      

    </StyledButton>
  );
};

export default Button;
