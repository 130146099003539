import axios from 'axios';
const {
  REACT_APP_AUTHENTICATION_SERVICE_URL,
  REACT_APP_ORDERS_SERVICE_URL,
  REACT_APP_PAYMENT_SERVICE_URL,
} = process.env;

export const getAddresses = async (userId) => {
  const response = await axios
    .get(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/admin/user/${userId}/profile`)
    .then((response) => {
      const addresses = response.data.payload.addresses ? response.data.payload.addresses : [];
      return addresses;
    });
  return response;
};

export const deleteAddress = async (id) => {
  const response = await axios
    .delete(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/address/${id}`)
    .then((response) => response.data.payload);
  return response;
};

export const getCountries = async () => {
  const response = await axios
    .get(`${REACT_APP_ORDERS_SERVICE_URL}/region/countries`)
    .then((response) => response.data.payload);
  return response;
};

export const getStates = async (id) => {
  const response = await axios
    .get(`${REACT_APP_ORDERS_SERVICE_URL}/region/states/${id}`)
    .then((response) => response.data.payload);
  return response;
};

export const getCities = async (id) => {
  const response = await axios
    .get(`${REACT_APP_ORDERS_SERVICE_URL}/region/cities/${id}`)
    .then((response) => response.data.payload);
  return response;
};

export const getTax = async (data) => {
  const response = await axios
    .post(`${REACT_APP_ORDERS_SERVICE_URL}/tax/invoice`, data)
    .then((response) => response.data.payload);
  return response;
};

export const getShipping = async (data) => {
  const response = await axios
    .post(`${REACT_APP_ORDERS_SERVICE_URL}/area-zone/invoice`, data)
    .then((response) => response.data.payload);
  return response;
};

export const getPickup = async () => {
  const response = await axios
    .get(`${REACT_APP_ORDERS_SERVICE_URL}/location`)
    .then((response) => response.data.payload);
  return response;
};

export const getPaymentMethod = async () => {
  const response = await axios
    .get(`${REACT_APP_PAYMENT_SERVICE_URL}/admin/payment-method`)
    .then((response) => response.data.payload);
  return response;
};

export const getDiscount = async (discountCode) => {
  const response = await axios
    .get(`${REACT_APP_ORDERS_SERVICE_URL}/discount-code/check-discountId/${discountCode}`)
    .then((response) => response.data.payload);
  return response;
};

export const createInvoice = async (data, userId) => {
  const response = await axios
    .post(`${REACT_APP_ORDERS_SERVICE_URL}/invoice/post-order-by-admin/${userId}`, data)
    .then((response) => response.data.payload);
  return response;
};

export const editInvoice = async (invoiceId, data) => {
  const response = await axios
    .put(`${REACT_APP_ORDERS_SERVICE_URL}/invoice/${invoiceId}`, data)
    .then((response) => response.data.payload);
  return response;
};

export const createPayment = async (data) => {
  const response = await axios
    .post(`${REACT_APP_PAYMENT_SERVICE_URL}/credit/charge`, data)
    .then((response) => response.data.payload);
  return response;
};

export const createPaymentPaypal = async (data) => {
  const response = await axios
    .post(`${REACT_APP_PAYMENT_SERVICE_URL}/credit/charge/paypal`, data)
    .then((response) => response.data.payload);
  return response;
};

export const finalPayment = async (data) => {
  const response = await axios
    .post(`${REACT_APP_PAYMENT_SERVICE_URL}/payment/${data.invoice_id}`, data)
    .then((response) => response.data.payload);
  return response;
};
