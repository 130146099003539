import React, { useContext } from 'react';
import styled from 'styled-components';
import { CounterContext } from 'context/bestContext';
import { ThemeContext } from 'context/themeContext';
import Name from './components/name';
import Items from './components/items';
import Space from './components/items';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 120;
  opacity: ${(props) => (props.open ? '1' : '0')};
  visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
  /* background-color: rgba(0,0,0,0.8); */
  transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out;
`;

const CloseWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 120;
  display: ${(props) => (props.open ? 'block' : 'none')};
`;

const DrawerWrapper = styled.div`
  position: absolute;
  top: 0;
  right: ${(props) => (props.open ? '0' : '-14.523vw')};
  width: 14.523vw;
  height: 100vh;
  z-index: 121;
  background-color: ${(props) => props.theme.cardBackground};
  box-shadow: ${(props) =>
    props.open ? '0 0 15px 1px rgb(94,95,98,0.3)' : '0 0 15px 1px rgb(94,95,98,0)'};
  transition: 200ms ease-in-out;
`;

function Sidebar() {
  const { darkThemeSelected, theme } = useContext(ThemeContext);
  const { showDrawer, setShowDrawer } = useContext(CounterContext);
  return (
    <Wrapper open={showDrawer}>
      <CloseWrapper open={showDrawer} onClick={() => setShowDrawer(false)} />
      <DrawerWrapper open={showDrawer} theme={theme}>
        <Name />
        <Items setShowDrawer={setShowDrawer} />
      </DrawerWrapper>
    </Wrapper>
  );
}

export default React.memo(Sidebar);
