//we fetch list of all currecies from server in  (there is a currencies collection)
let currencies = [
  
];
export const getCurrencies=()=>currencies;
export const setCurrencies=(currenciesList)=>{
  currencies=currenciesList;
}

export const setCurrencyByType = (name) => {
  let result;
  currencies.map((currency) => {
    if (name === currency.name) {
      result = currency.id;
    }
  });
  return result;
};

export const setCurrencyById = (name) => {
  let result;
  currencies.map((currency) => {
    if (name === currency.id) {
      result = currency.name;
    }
  });
  return result;
};
