import React, { useContext } from 'react';
import { ThemeContext } from 'context/themeContext';
import { Wrapper, ImageWrapper, Step5Icon, Content, Title, Text, Inputs } from './style';
import Input from 'components/input';

const Step1 = (props) => {
  const { theme } = useContext(ThemeContext);
  const { display, onChange, paymentMethodName } = props;
  return (
    <Wrapper display={display}>
      <ImageWrapper theme={theme}>
        <Step5Icon />
      </ImageWrapper>
      <Content>
        <Title theme={theme}>Add your shop payment method</Title>
        <Text theme={theme}>
          Add a custom payment method name, to allow your users register new orders. For example
          Cash, Checks, Debit cards, etc.
        </Text>
        <Inputs>
          <Input
            placeholder="Payment method name"
            onChange={onChange}
            inputTarget="payment-method-name"
            value={paymentMethodName}
            size="full"
            validate={true}
          />
        </Inputs>
      </Content>
    </Wrapper>
  );
};

export default Step1;
