import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ThemeContext } from 'context/themeContext';
import EyeSVG from 'asset/eye.svg';
import Button from 'components/button';

const EyeIcon = styled.img.attrs({
  src: EyeSVG,
})`
  position: absolute;
  width: 2vw;
  height: 2vw;
  top: calc(50% - 1vw);
  right: 1.5vw;
  cursor: pointer;
  filter: ${(props) =>
    props.darkThemeSelected
      ? 'invert(100%) sepia(100%) saturate(0%) hue-rotate(21deg) brightness(103%) contrast(101%)'
      : 'invert(76%) sepia(3%) saturate(22%) hue-rotate(321deg) brightness(81%) contrast(81%)'};
`;

const Wrapper = styled.div`
  position: relative;
`;

const StyledInput = styled.input`
  position: absolute;
  top: 0;
  background-color: ${(props) => props.theme.inputBackground};
  border: none;
  color: ${(props) => props.theme.textPrimary};
  padding: ${(props) =>
    props.size === 'larg' ? '1.56vw' : props.size === 'small' ? '0.624vw' : '1.04vw'};
  ${(props) => props.info && 'padding-right'}: ${(props) =>
    props.size === 'larg'
      ? 2 * 1.56 + 'vw'
      : props.size === 'small'
        ? 2 * 0.624 + 'vw'
        : 2 * 1.04 + 'vw'};
  box-sizing: border-box;
  font-size: ${(props) =>
    props.size === 'larg' ? '1.4vw' : props.size === 'small' ? '0.728vw' : '0.937vw'};
  border-radius: ${(props) =>
    props.size === 'larg' ? '1.301vw' : props.size === 'small' ? '0.572vw' : '0.832vw'};
  text-align: left;
  direction: ltr;
  box-shadow: 0 0 0 0.2rem ${(props) => (props.validate === false ? 'red' : 'rgba(94,95,98,0)')};
  ::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
  &:active,
  &:focus {
    border: none;
    background-color: ${(props) => props.theme.inputBackground};
    border-color: red;
    color: ${(props) => props.theme.textPrimary};
    opacity: 1;
    outline: 0px;
    box-shadow: 0 0 0 0.2rem ${(props) => (props.validate === false ? 'red' : 'rgb(94,95,98)')};
  }
  ${(props)=> (props.showArrows)?
    '':
    `/* Chrome, Safari, Edge, Opera */
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  
    /* Firefox */
    
    -moz-appearance: textfield;`
  }
  
`;

const Label = styled.label`
  position: absolute;
  pointer-events: none;
  top: ${(props) =>
    props.size === 'larg' ? '1.3vw' : props.size === 'small' ? '0.5vw' : '0.95vw'};
  left: ${(props) =>
    props.size === 'larg' ? '1.56vw' : props.size === 'small' ? '0.624vw' : '1.04vw'};
  transition: 0.3s ease all;
  font-size: ${(props) =>
    props.size === 'larg' ? '1.4vw' : props.size === 'small' ? '0.728vw' : '0.937vw'};
  color: ${(props) => (props.validate === false ? props.theme.redColor : props.theme.textSecondary)};

  ${StyledInput}:focus ~ & {
    top: ${(props) =>
    props.size === 'larg' ? '-1.80vw' : props.size === 'small' ? '-1.1vw' : '-1.4vw'};
    left: ${(props) =>
    props.size === 'larg' ? '0.989vw' : props.size === 'small' ? '0.364vw' : '0.624vw'};
    font-size: ${(props) =>
    props.size === 'larg' ? '0.93vw' : props.size === 'small' ? '0.56vw' : '0.728vw'};
    opacity: 1;
  }
`;
const OptionalStyle = styled.span`
  ${StyledInput}:focus ~ & {
    display: none;
    color: blue;
  }
`;

const ErrorMsg = styled.label`
  position: absolute;
  pointer-events: none;
  transition: 0.3s ease all;
  bottom: ${(props) =>
    props.size === 'larg' ? '-1.50vw' : props.size === 'small' ? '-0.8vw' : '-1.1vw'};
  right: ${(props) =>
    props.size === 'larg' ? '0.989vw' : props.size === 'small' ? '0.364vw' : '0.624vw'};
  font-size: ${(props) =>
    props.size === 'larg' ? '0.832vw' : props.size === 'small' ? '0.416vw' : '0.52vw'};
  opacity: ${(props) => (props.validate ? '0' : '1')};
  transition: 0.3s ease all;
  color: ${(props) => props.theme.redColor};
`;

const InfoSign = styled.div`
  position: absolute;
  right: ${(props) =>
    props.size === 'larg' ? '1.04vw' : props.size === 'small' ? '0.56vw' : '0.78vw'};
  top: 50%;
  transform: translateY(-50%);
  width: ${(props) =>
    props.size === 'larg' ? '1.04vw' : props.size === 'small' ? '0.56vw' : '0.78vw'};
  height: ${(props) =>
    props.size === 'larg' ? '1.04vw' : props.size === 'small' ? '0.56vw' : '0.78vw'};
  border-radius: 50%;
  display: ${(props) => (props.display ? 'block' : 'none')};
  transition: 0.3s ease all;
  background-color: ${(props) => props.theme.textPrimary + '80'};
  z-index: ${(props) => (props.focused ? '99' : '1')};
  &:hover {
    background-color: ${(props) => props.theme.textPrimary};
  }
`;

const InfoText = styled.div`
  position: absolute;
  left: 50%;
  top: 200%;
  width: 8.797vw;
  transform: translateX(-50%) ${(props) => (props.focused ? 'scale(1)' : 'scale(0.3)')};
  transform-origin: 50% 0%;
  border-radius: 0.884vw;
  opacity: ${(props) => (props.focused ? '1' : '0')};
  visibility: ${(props) => (props.focused ? 'visible' : 'hidden')};
  transition: 0.3s ease all;
  background-color: ${(props) => props.theme.SecondaryBackground};
  color: ${(props) => props.theme.textPrimary};
  box-sizing: border-box;
  padding: 1.04vw;
  font-size: 0.624vw;
  line-height: 1.7;
`;
const StyledDiv = styled.div`
  margin-left: 2.3vw;
`;
const Input = (props) => {
  const { theme, darkThemeSelected } = useContext(ThemeContext);
  const [showPassword, setShowPassword] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const infoRef = useRef();
  const {
    placeholder,
    type,
    name,
    value,
    validate,
    errMsg,
    optional,
    info,
    infoMessage,
    infoLink,
    children,
    disabled=false
  } = props;
  const onEyeClick = () => {
    setShowPassword(!showPassword);
  };
  const toggleInfo = (e, value) => {
    if (value) {
      if (!showInfo) {
        infoRef.current.focus();
        setShowInfo(value);
      } else {
        setShowInfo(false);
      }
    } else {
      if (!infoRef.current.contains(e.relatedTarget)) {
        setShowInfo(value);
      }
    }
  };
  useEffect(() => {
    if (showInfo) {
    }
  }, [showInfo]);
  return (
    <Wrapper
      style={
        props.size === 'larg'
          ? { width: '22.28vw', height: '4.52vw' }
          : props.size === 'small'
            ? { width: '9.31vw', height: '1.978vw' }
            : props.size === 'long'
              ? { width: '18.11vw', height: '3.0713vw' }
              : props.size === 'full'
                ? { width: '100%', height: '3.0713vw' }
                : props.size === 'medium' ?
                 { width: '12.077vw', height: '3.0713vw' }
                : props.size === 'payment'
                  ? { width: '34.357vw', height: '3.0713vw' }
                  : props.size ?
                      { width: props.size, height: '3.0713vw' }
                    : { width: '12.077vw', height: '3.0713vw' }
      }
    >
      <StyledInput
        theme={theme}
        size={props.size}
        autofocus={props.autofocus}
        inputType={props.width}
        align={props.align}
        type={showPassword ? 'text' : type}
        name={name}
        onKeyDown={props.onKeyDown}
        validate={validate}
        showArrows={props.showArrows}
        info={info}
        onChange={(event) => {
          props.onChange(props.inputTarget, event.target.value, props.index, props.dIndex);
        }}
        value={value}
        disabled={disabled}
        style={
          props.size === 'larg'
            ? { width: '22.28vw', height: '4.52vw' }
            : props.size === 'small'
              ? { width: '9.31vw', height: '1.978vw' }
              : props.size === 'long'
                ? { width: '18.11vw', height: '3.0713vw' }
                : props.size === 'full'
                  ? { width: '100%', height: '3.0713vw' }
                  : props.size === 'medium' ?
                    { width: '12.077vw', height: '3.0713vw' }
                  : props.size === 'payment'
                    ? { width: '34.357vw', height: '3.0713vw' }
                    : props.size ?
                        { width: props.size, height: '3.0713vw' }
                      : { width: '12.077vw', height: '3.0713vw' }
        }
      />
      <Label
        style={
          value !== ''
            ? {
              top:
                props.size === 'larg' ? '-1.80vw' : props.size === 'small' ? '-1.1vw' : '-1.4vw',
              left:
                props.size === 'larg'
                  ? '0.989vw'
                  : props.size === 'small'
                    ? '0.364vw'
                    : '0.624vw',
              fontSize:
                props.size === 'larg' ? '0.93vw' : props.size === 'small' ? '0.56vw' : '0.728vw',
            }
            : {}
        }
        theme={theme}
        inputType={props.width}
        validate={validate}
        size={props.size}
      >
        {placeholder}
        <OptionalStyle>{value === '' && props.optional ? props.optional : ''}</OptionalStyle>
      </Label>
      <ErrorMsg theme={theme} size={props.size} validate={validate} inputType={props.width}>
        {props.errMsg}
      </ErrorMsg>
      {(type=='password') && <EyeIcon darkThemeSelected={darkThemeSelected} onClick={onEyeClick} />}
      <InfoSign
        tabIndex="1"
        onClick={(e) => toggleInfo(e, true)}
        onBlur={(e) => toggleInfo(e, false)}
        display={info}
        theme={theme}
        size={props.size}
        focused={showInfo}
      >
        <InfoText
          focused={showInfo}
          theme={theme}
          ref={infoRef}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {infoMessage}
          {infoLink && (
            <StyledDiv>
              <a
                target="_blank"
                href={`https://${infoLink}`}
                style={{ textDecoration: 'none', PointerEvent: 'none' }}
                rel="noreferrer"
              >
                <Button
                  onClick={() => console.log('')}
                  marginTop={0.56}
                  text="Learn more"
                  size="small"
                  type="secondary"
                />
              </a>
            </StyledDiv>
          )}
        </InfoText>
      </InfoSign>
      {props.children}
    </Wrapper>
  );
};

export default Input;
