import axios from 'axios';
const {
  REACT_APP_AUTHENTICATION_SERVICE_URL,
  REACT_APP_UPLOAD_SERVICE_URL,
  REACT_APP_CHAT_SERVICE_URL,
} = process.env;

export const checkUserRegistered = async (cellphone) => {
  const response = await axios
    .get(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/login/${cellphone}`, {
      headers: {
        family: cellphone,
      },
    })
    .then((response) => response.data.payload);
  return response;
};

export const loginWithPassword = async (data) => {
  const response = await axios
    .post(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/login/with-password`, data, {
      headers: {
        family: data.cellphone,
      },
    })
    .then((response) => response.data.payload);
  return response;
};

export const loginWithGoogle = async (data) => {
  const response = await axios
    .post(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/login/login-by-google-access-token`, data, {
      headers: {
        family: data.cellphone,
      },
    })
    .then((response) => response.data.payload);
  return response;
};

export const registerUser = async (data) => {
  const response = await axios
    .post(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/register/new-admin`, data, {
      headers: {
        family: data.cellphone,
      },
    })
    .then((response) => response.data.payload);
  return response;
};

export const registerUserByAdmin = async (data) => {
  const response = await axios
    .post(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/admin/user/add`, data)
    .then((response) => response.data.payload);
  return response;
};

export const confirmUser = async (data) => {
  const response = await axios
    .post(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/otp/confirmation`, data, {
      headers: {
        family: data.cellphone,
      },
    })
    .then((response) => response.data.payload);
  return response;
};

export const forgetPassword = async (email) => {
  const response = await axios
    .get(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/otp?cellphone=${email}`, {
      headers: {
        family: email,
      },
    })
    .then((response) => response.data.payload);
  return response;
};

export const getUserProfile = async () => {
  const response = await axios
    .get(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/profile`)
    .then((response) => response.data.payload);
  return response;
};

export const updateUserProfile = async (data) => {
  const response = await axios
    .put(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/profile`, data)
    .then((response) => response.data.payload);
  return response;
};

export const getUserUploadSize = async () => {
  const response = await axios
    .get(`${REACT_APP_UPLOAD_SERVICE_URL}/upload/admin/uploads-size`)
    .then((response) => response.data.payload);
  return response;
};

export const getNotifications = async () => {
  const response = await axios
    .get(`${REACT_APP_CHAT_SERVICE_URL}/notification/admin-notifications`)
    .then((response) => response.data.payload);
  return response;
};

export const getHubNotifications = async (hubId) => {
  const response = await axios
    .get(`${REACT_APP_CHAT_SERVICE_URL}/notification/hub-notifications/${hubId}`)
    .then((response) => response.data.payload);
  return response;
};

export const acceptNewRole = async (token) => {
  const response = await axios
    .get(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/role/confirm-admin-role/${token}`)
    .then((response) => response.data.payload);
  return response;
};

export const rejectNewRole = async (token) => {
  const response = await axios
    .get(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/role/reject-admin-role/${token}`)
    .then((response) => response.data.payload);
  return response;
};

export const getFamilyBySubdomain = async (family) => {
  const response = await axios
    .get(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/login/family-details/${family}`)
    .then((response) => response.data.payload);
  return response;
};

export const getCurrencies = async () => {
  const response = await axios
    .get(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/currency/all`)
    .then((response) => response.data.payload);
  return response;
};