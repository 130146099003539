import React, { useContext } from 'react';
import { ThemeContext } from 'context/themeContext';
import { Wrapper, ImageWrapper, Step4Icon, Content, Title, Text, Inputs } from './style';
import Input from 'components/input';

const Step1 = (props) => {
  const { theme } = useContext(ThemeContext);
  const { display, onChange, locationName } = props;
  return (
    <Wrapper display={display}>
      <ImageWrapper theme={theme}>
        <Step4Icon />
      </ImageWrapper>
      <Content>
        <Title theme={theme}>Add your business location </Title>
        <Text theme={theme}>
          Add your business location. you can track inventory and fulfill orders at your locations.
          for example Office, Factory no.1, etc.
        </Text>
        <Inputs>
          <Input
            placeholder="Location name"
            onChange={onChange}
            inputTarget="location-name"
            value={locationName}
            size="full"
            validate={true}
          />
        </Inputs>
      </Content>
    </Wrapper>
  );
};

export default Step1;
