import React, { useContext } from 'react';
import { ThemeContext } from 'context/themeContext';
import { Wrapper, ImageWrapper, Step2Icon, Content, Title, Text, Inputs } from './style';
import { materialTypes, setMaterialByType, setMaterialById } from 'utils/material-types';
import Input from 'components/input';
import DropDown from 'components/drop-down';

const Step1 = (props) => {
  const { theme } = useContext(ThemeContext);
  const { display, onChange, technology, materialType, setMaterialType } = props;
  const selected = setMaterialByType(materialType);
  const setSelected = (id) => {
    let helper = setMaterialById(id);
    setMaterialType(helper);
  };
  return (
    <Wrapper display={display}>
      <ImageWrapper theme={theme}>
        <Step2Icon />
      </ImageWrapper>
      <Content>
        <Title theme={theme}>Add 3D print technology</Title>
        <Text theme={theme}>
          Choose the type of printing service your business offers. Also choose the pricing method
          of that technology.
        </Text>
        <Inputs>
          <Input
            placeholder="Technology"
            onChange={onChange}
            inputTarget="technology"
            value={technology}
            validate={true}
          />
          <DropDown
            options={materialTypes}
            selected={selected}
            setSelected={setSelected}
            label="Pricing by"
          />
        </Inputs>
      </Content>
    </Wrapper>
  );
};

export default Step1;
