import React, { useContext, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { ThemeContext } from 'context/themeContext';
import { useDropzone } from 'react-dropzone';
import { uploadAttachedFile } from 'containers/orders/container/api';
import MainContext from 'MainContext';
import { useEffect } from 'react';

const StyledWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  opacity: ${(props) => (props.open ? '0.95' : '0')};
  backdrop-filter: ${(props) => (props.open ? 'blur( 4px )' : 'blur( 0px )')};
  -webkit-backdrop-filter: ${(props) => (props.open ? 'blur( 4px )' : 'blur( 0px )')};
  overflow: auto;
  overflow-x: hidden;
  transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out;
  visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
`;

const CloseWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  display: ${(props) => (props.open ? 'block' : 'none')};
`;

const StyledContentWrapper = styled.div`
  background-color: ${(props) => props.theme.primaryBackground};
  position: relative;
  width: 39.979vw;
  height:${(props) => props.height ?? "auto"};
  margin: auto;
  margin-top: ${(props) =>
    props.yesNoDialog ? (props.open ? '15vw' : '5vw') : props.open ? '5vw' : '-5vw'};
  margin-bottom: 5vw;
  z-index: 101;
  transition: margin 200ms ease-in-out;
  border-radius: 2.08vw;
`;

const DragWrapper = styled.div`
  position:relative;
`;

const DragWrapperChildren = styled.div`
  opacity:1;
  position:absolute;
  width:100%;
`;

const DragWrapperLayer = styled.div`
  position:absolute;
  height:${(props) => props.height + 'px'};
  width:100%;
  background-color:#0000ffaa;
`;

const TextWrapper = styled.div`
  margin-top:50vh;
  margin-left:14vw;
  color:#ffffff;
`;

const Modal = (props) => {
  const { theme } = useContext(ThemeContext);
  const { open, toggle, yesNoDialog } = props;
  const { updateToasterContext } = useContext(MainContext).toasters;
  const { getAttachFileContext } = useContext(MainContext).orders;
  const close = useRef(null);
  const height = useRef(null);
  const width = useRef(null);

  const onWrapperClick = (e) => {
    if (!close.current.contains(e.target)) {
      e.stopPropagation();
      toggle();
    }

  };
  const onDrop = (file) => {
    const data = new FormData();
    data.append('attach_file', file[0])
    data.append('userId', props.profile._id)
    data.append('invoiceId', props.selectedInvoiceId)
    data.append('orderId', props.selectedOrderId)
    data.append('fileName', file[0].name)
    data.append('fileSize', file[0].size)
    updateToasterContext({
      type: 'info',
      message: 'Uploading...',
    });
    uploadAttachedFile(data).then(() => {
      updateToasterContext({
        type: 'success',
        message: 'File has been uploaded successfully.',
      })
      getAttachFileContext(props.selectedOrderId)
    }).catch(() => {
      updateToasterContext({
        type: 'error',
        message: 'There was an error uploading the attachment.'
      })
    })
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <StyledWrapper theme={theme} open={open} onClick={onWrapperClick} >
      <div {...getRootProps()}>
        <CloseWrapper open={open} />
        <StyledContentWrapper isDragActive={isDragActive} theme={theme} open={open} yesNoDialog={yesNoDialog} height={props.height} ref={close}>
          {
            isDragActive ? (
              <>
                <DragWrapper>
                  <DragWrapperChildren ref={height}>
                    {props.children}
                  </DragWrapperChildren>
                  <DragWrapperLayer height={height.current?.clientHeight}>
                    <TextWrapper>
                      Drop the files here to upload
                    </TextWrapper>
                  </DragWrapperLayer>
                </DragWrapper>
              </>
            ) :
              <div>
                {props.children}
              </div>
          }
        </StyledContentWrapper>
      </div>
    </StyledWrapper>
  );
};

export default Modal;
