import { types } from './actions';

const reducer = (state, action) => {
  switch (action.type) {
    case types.SET_INVOICES:
      return { ...state, invoices: action.payload };
    case types.UPDATE_INVOICES:
      return { ...state, invoices: [...state.invoices, ...action.payload] };
    case types.TOTAL_INVOICES:
      return { ...state, totalInvoices: action.payload };
    case types.ORDER_MODAL_STATE:
      return { ...state, orderModalState: action.payload };
    case types.ORDER_HUBS:
      return { ...state, orderHubs: action.payload };
      case types.SET_ATTACH_FILES:
      return { ...state, attachments: action.payload };
    default:
      return state;
  }
};

export const key = 'orders';
export default reducer;
