import axios from 'axios';
const { REACT_APP_ORDERS_SERVICE_URL } = process.env;

export const getDiscounts = async (reqData) => {
  const response = await axios
    .get(`${REACT_APP_ORDERS_SERVICE_URL}/discount-code?page=${reqData.pageNumber}`)
    .then((response) => response.data.payload);
  return response;
};

export const createDiscount = async (data) => {
  const response = await axios
    .post(`${REACT_APP_ORDERS_SERVICE_URL}/discount-code`, data)
    .then((response) => response.data.payload);
  return response;
};

export const editDiscount = async (data, id) => {
  const response = await axios
    .put(`${REACT_APP_ORDERS_SERVICE_URL}/discount-code/${id}`, data)
    .then((response) => response.data.payload);
  return response;
};

export const toggleDiscount = async (reqData) => {
  const response = await axios
    .get(`${REACT_APP_ORDERS_SERVICE_URL}/discount-code/is-active/${reqData.name}`)
    .then((response) => response.data.meta.status);
  return response;
};

export const deleteDiscount = async (id) => {
  const response = await axios
    .delete(`${REACT_APP_ORDERS_SERVICE_URL}/discount-code/${id}`)
    .then((response) => response.data.meta.status);
  return response;
};
