import React, { useState, useContext, useRef } from 'react';
import styled from 'styled-components';
import { ThemeContext } from 'context/themeContext';
import TriangleSVG from 'asset/triangle.svg';

const Triangle = styled.img.attrs({
  src: TriangleSVG,
})`
  position: absolute;
  top: 1.24vw;
  right: 1.04vw;
  width: 0.889vw;
  height: 0.889vw;
  transform: ${(props) => (props.focused ? 'rotate(-180deg)' : 'rotate(-90deg)')};
  filter: ${(props) =>
    props.darkThemeSelected
      ? 'invert(83%) sepia(8%) saturate(0%) hue-rotate(278deg) brightness(94%) contrast(80%)'
      : 'invert(39%) sepia(8%) saturate(73%) hue-rotate(169deg) brightness(93%) contrast(94%)'};
  transition: 200ms linear;
`;

const Wrapper = styled.div`
  margin: auto;
  box-sizing: border-box;
  width: 10.26vw;
  height: 3.489vw;
  background-color: ${(props) => props.theme.pure};
  position: relative;
  outline: none;
  border-radius: 1.7445vw;
  cursor: pointer;
  z-index: 2;
`;

const UnSelect = styled.div`
  box-sizing: border-box;
  width: 10.26vw;
  height: 3.489vw;
  position: absolute;
  top: 0;
  left: 0;
  outline: none;
  border-radius: 1.7445vw;
  display: ${(props) => !props.display && 'none'};
`;

const Choices = styled.div`
  box-sizing: border-box;
  width: 10.26vw;
  height: ${(props) =>
    props.focused ? 3.489 * Math.min(4, props.choicesArrayLength + 1) + 'vw' : '3.489vw'};
  background-color: ${(props) => props.theme.pure};
  position: absolute;
  top: 0vw;
  left: 0;
  border-radius: 1.7445vw;
  transition: 200ms linear;
  overflow: hidden;
`;

const ScrollArea = styled.div`
  box-sizing: border-box;
  width: ${(props) => (props.size === 'full' ? 'calc(100% - 0.3vw)' : '11.80vw')};
  height: ${(props) =>
    props.focused ? 3.489 * Math.min(3, props.choicesArrayLength) + 'vw' : '0vw'};
  background-color: ${(props) => props.theme.pure};
  position: absolute;
  top: 3.489vw;
  left: 0;
  transition: 200ms linear;
  overflow: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 0.312vw;
    border-radius: 0.312vw;
    background-color: ${(props) => props.theme.SecondaryBackground};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 0.26vw ${(props) => props.theme.SecondaryBackground};  */
    border-radius: 0.312vw;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.borderBackground};
    border: 0vw solid #f4f4f4;
    border-radius: 0.312vw;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.tagBorder};
  }
`;

const Selected = styled.div`
  box-sizing: border-box;
  width: 10.26vw;
  height: 3.489vw;
  color: ${(props) => props.theme.textSecondary};
  font-size: 0.937vw;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: 1.04vw;
  transition: 300ms linear;
`;

const Choice = styled(Selected)`
  background-color: ${(props) => props.theme.pure};
  &:hover {
    background-color: ${(props) => props.theme.SecondaryBackground};
  }
  transition: 300ms linear;
`;
const Selector = (props) => {
  const { theme, darkThemeSelected } = useContext(ThemeContext);
  const [focused, setFocused] = useState(false);
  const wrapperRef = useRef();
  const { options, selected, setSelected, label, size, type } = props;
  let choicesArray = [];
  let selectedName = '';
  options.map((option) => {
    choicesArray.push(
      <Choice
        theme={theme}
        size={size}
        onClick={() => {
          setSelected(option.id);
          wrapperRef.current.blur();
        }}
      >
        {option.name}
      </Choice>
    );
    if (selected === option.id) {
      selectedName = option.name;
    }
  });
  const onKeyDown = (e) => {
    console.log(1);
  };
  const onBlur = () => {
    setFocused(false);
  };
  const onFocus = () => {
    setFocused(true);
  };
  const onUnSelectClick = () => {
    if (focused) {
      wrapperRef.current.blur();
    }
  };

  return (
    <Wrapper
      theme={theme}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyDown={(e) => onKeyDown(e)}
      tabIndex="1"
      focused={focused}
      ref={wrapperRef}
      size={size}
    >
      <Choices focused={focused} theme={theme} choicesArrayLength={choicesArray.length} size={size}>
        <Triangle focused={focused} darkThemeSelected={darkThemeSelected} />
        <Selected theme={theme} size={size}>
          {selectedName}
        </Selected>
        <ScrollArea
          focused={focused}
          theme={theme}
          choicesArrayLength={choicesArray.length}
          size={size}
        >
          {choicesArray}
        </ScrollArea>
      </Choices>
      <UnSelect onClick={onUnSelectClick} display={focused} size={size} />
    </Wrapper>
  );
};
export default Selector;
