import axios from 'axios';
const { REACT_APP_ORDERS_SERVICE_URL } = process.env;

export const getCart = async (userId) => {
  const response = await axios
    .get(`${REACT_APP_ORDERS_SERVICE_URL}/order/cart/post-order-by-admin/${userId}`)
    .then((response) => response.data.payload);
  return response;
};

export const updateOrderQuantity = async (data, userId) => {
  const response = await axios
    .put(`${REACT_APP_ORDERS_SERVICE_URL}/order/quantity/post-order-by-admin/${userId}`, data)
    .then((response) => response.data.payload);
  return response;
};

export const deleteOrder = async (userId, id) => {
  const response = await axios
    .delete(`${REACT_APP_ORDERS_SERVICE_URL}/order/delete-order-by-admin/${userId}/${id}`)
    .then((response) => response.data.payload);
  return response;
};

export const getInvoice = async (id) => {
  const response = await axios
    .get(`${REACT_APP_ORDERS_SERVICE_URL}/invoice/${id}`)
    .then((response) => response.data.payload);
  return response;
};
