export const materialTypes = [
  { name: 'weight', text: 'Weight', id: 0 },
  { name: 'volume', text: 'Volume', id: 1 },
  { name: 'time', text: 'Time', id: 2 },
  { name: 'boundingBox', text: 'Bounding Box', id: 3 },
];

export const setMaterialByType = (name) => {
  let result;
  materialTypes.map((materialType) => {
    if (name === materialType.name) {
      result = materialType.id;
    }
  });
  return result;
};

export const setMaterialById = (name) => {
  let result;
  materialTypes.map((materialType) => {
    if (name === materialType.id) {
      result = materialType.name;
    }
  });
  return result;
};
