import React, { useContext } from 'react';
import { ThemeContext } from 'context/themeContext';
import { Wrapper, ImageWrapper, Step3Icon, Content, Title, Text, Inputs } from './style';
import Input from 'components/input';

const Step1 = (props) => {
  const { theme } = useContext(ThemeContext);
  const { display, onChange, materialName, price } = props;
  return (
    <Wrapper display={display}>
      <ImageWrapper theme={theme}>
        <Step3Icon />
      </ImageWrapper>
      <Content>
        <Title theme={theme}>Add 3D print material</Title>
        <Text theme={theme}>
        Please input the material name and its 3D printing price. Technology pricing method applies to materials within its subset.
        </Text>
        <Inputs>
          <Input
            placeholder="Material Name"
            onChange={onChange}
            inputTarget="material-name"
            value={materialName}
            validate={true}
          />
          <Input
            placeholder="Price"
            onChange={onChange}
            inputTarget="price"
            value={price}
            validate={true}
          />
        </Inputs>
      </Content>
    </Wrapper>
  );
};

export default Step1;
