import React, { useState, useContext, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { ThemeContext } from 'context/themeContext';
import TriangleSVG from 'asset/triangle.svg';

const Triangle = styled.img.attrs({
  src: TriangleSVG,
})`
  position: absolute;
  top: 1.04vw;
  ${(props)=>(props.rtlDirection ? 
    'left: 1.04vw;':
    'right: 1.04vw;'
  )}
  
  width: 0.889vw;
  height: 0.889vw;
  transform: ${(props) => (props.focused ? 
      'rotate(-180deg)' 
      : 
      (props.rtlDirection ? 
        'rotate(-270deg)':
        'rotate(-90deg)'
      )
  )};
  filter: ${(props) =>
    props.darkThemeSelected
      ? 'invert(100%) sepia(100%) saturate(0%) hue-rotate(21deg) brightness(103%) contrast(101%)'
      : 'invert(76%) sepia(3%) saturate(22%) hue-rotate(321deg) brightness(81%) contrast(81%)'};
  transition: 200ms linear;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: ${(props) => (props.size === 'full' ? '100%' : (props.size ? props.size : '12.077vw'))};
  height: 3.071vw;
  background-color: ${(props) => props.theme.inputBackground};
  position: relative;
  outline: none;
  border-radius: 0.78vw;
  cursor: pointer;
  z-index: ${(props) => (props.focusedProp ? 3 : 2)};
  filter:${(props)=>(props.disabled)?'contrast(0.7)':'none'}
`;

const UnSelect = styled.div`
  box-sizing: border-box;
  width: ${(props) => (props.size === 'full' ? '100%' : (props.size ? props.size : '12.077vw'))};
  height: 3.071vw;
  position: absolute;
  top: 0;
  left: 0;
  outline: none;
  border-radius: 0.78vw;
  display: ${(props) => !props.display && 'none'};
`;

const Choices = styled.div`
  direction:${(props) => props.rtlDirection? 'rtl' : 'ltr'};
  box-sizing: border-box;
  width: ${(props) => (props.size === 'full' ? '100%' : (props.size ? props.size : '12.077vw'))};
  height: ${(props) =>
    props.focused ? 3.071 * Math.min(4, props.choicesArrayLength + 1) + 'vw' : '3.071vw'};
  background-color: ${(props) => props.theme.inputBackground};
  position: absolute;
  top: 0vw;
  left: 0;
  border-radius: 0.78vw;
  transition: 200ms linear;
  overflow: hidden;
`;

const ScrollArea = styled.div`
  box-sizing: border-box;
  width: ${(props) => (props.size === 'full' ? 'calc(100% - 0.3vw)' : (props.size ? 'calc('+props.size+' - 0.3vw)' : 'calc( 12.077vw - 0.3vw)'))};
  height: ${(props) =>
    props.focused ? 3.071 * Math.min(3, props.choicesArrayLength) + 'vw' : '0vw'};
  background-color: ${(props) => props.theme.inputBackground};
  position: absolute;
  top: 3.071vw;
  left: 0;
  transition: 200ms linear;
  overflow: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 0.312vw;
    border-radius: 0.312vw;
    background-color: ${(props) => props.theme.SecondaryBackground};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 0.26vw ${(props) => props.theme.SecondaryBackground};  */
    border-radius: 0.312vw;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.borderBackground};
    border: 0vw solid #f4f4f4;
    border-radius: 0.312vw;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.tagBorder};
  }
`;

const Selected = styled.div`
  box-sizing: border-box;
  width: ${(props) => (props.size === 'full' ? '100%' : (props.size ? props.size : '12.077vw'))};
  height: 3.071vw;
  color: ${(props) => props.theme.textPrimary};
  font-size: 0.937vw;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: 1.04vw;
  padding-right: 1.04vw;
  transition: 300ms linear;
`;

const Choice = styled(Selected)`
  width:100%;
  background-color: ${(props) => props.index === props.down ? props.theme.pure : props.theme.inputBackground};
  &:hover {
    background-color: ${(props) => props.theme.pure};
  }
  transition: 300ms linear;
`;
const Label = styled.label`
  position: absolute;
  pointer-events: none;
  top: -1.4vw;
  ${props => props.rtlDirection ? 'right' : 'left'}: 0.624vw;
  font-size: 0.728vw;
  transition: 0.3s ease all;
  color: ${(props) => (props.focused ? props.theme.textPrimary : props.theme.textSecondary)};
`;
const DropDown = (props) => {
  const { theme, darkThemeSelected,rtlDirection } = useContext(ThemeContext);
  const [focused, setFocused] = useState(false);
  const [down, setDown] = useState(-1);
  const resultContainer = useRef(null);
  const wrapperRef = useRef();
  const { options, selected, setSelected, label, size,disabled=false } = props;
  const [arr, setarr] = useState([{}])
  let selectedName = '';

  useEffect(() => {
    if (!resultContainer.current) return
    resultContainer.current.scrollIntoView({
      behavior: "smooth", block: "nearest"
    })
  }, [down])

  useEffect(()=>{
    setarr(options)
  },[options])

  options.map((option) => {
    if (selected === option.id) {
      selectedName = option.symbol || option.text || option.name;
    }
  });
  const onKeyDown = (e) => {
    e.preventDefault();
    if(disabled){
      return;
    }

    if (e.key === "ArrowDown" && down + 1 < options.length) {
      setDown(down + 1)
    }
    if (e.key === "ArrowUp" && down >= 1) {
      setDown(down - 1)
    }
    if (e.key === "Enter") {
      setSelected(arr[down].id)
      setFocused(false)
    }
  };
  const onUnSelectClick = () => {
    
    if (focused) {
      wrapperRef.current.blur();
    }
  };
  const onClick=()=>{
    if(disabled){
      return;
    }
    setFocused(!focused)
    setDown(-1)
  }
  //close drop down when user clicks outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        // Close the dropdown here (e.g., call a function to set state)
        setFocused(false)
        wrapperRef.current.blur();
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [])
  return (
    <Wrapper
      theme={theme}
      onClick={onClick}
      onKeyDown={(e) => onKeyDown(e)}
      tabIndex="1"
      focused={focused}
      focusedProp={focused}
      ref={wrapperRef}
      size={size}
      disabled={disabled}
    >
      {disabled}
      <Label theme={theme} focused={focused} rtlDirection={rtlDirection}>
        {label}
      </Label>
      <Choices focused={focused} theme={theme} choicesArrayLength={arr.length} size={size} rtlDirection={rtlDirection}>
        <Triangle focused={focused} darkThemeSelected={darkThemeSelected} rtlDirection={rtlDirection} />
        <Selected theme={theme} size={size}>
          {selectedName}
        </Selected>
        <ScrollArea focused={focused} theme={theme} choicesArrayLength={arr.length} size={size}>
          {
            arr.map((option, index) => (
              <Choice
                key={index}
                index={index}
                down={down}
                theme={theme}
                focused={focused}
                onClick={() => {
                  setSelected(option.id);
                  // setName(option.name);
                  wrapperRef.current.blur();
                }}
                ref={index === down ? resultContainer : null}
              >
                {option.name}
              </Choice>
            ))
          }
        </ScrollArea>
      </Choices>
      <UnSelect onClick={onUnSelectClick} display={focused} size={size} />
    </Wrapper>
  );
};
export default DropDown;
