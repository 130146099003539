import actions from './actions';
import toasterActions from 'components/toaster/container/actions';
import {
  getRoles,
  getPermissions,
  createRole,
  editRole,
  getPaymentMethods,
  createPaymentMethod,
  editPaymentMethod,
  editPaymentMethodType,
  getCountries,
  getLocations,
  createLocation,
  editLocation,
  getShipping,
  createShipping,
  editShipping,
  getTaxes,
  createTax,
  editTax,
} from './api';

const settingPerformances = (dispatch) => {
  const setPaymentMethodsContext = (value) => {
    dispatch(actions.setPaymentMethods(value));
  };

  const setLocationsContext = (value) => {
    dispatch(actions.setLocations(value));
  };

  const setTaxesContext = (value) => {
    dispatch(actions.setTaxes(value));
  };

  const setShippingContext = (value) => {
    dispatch(actions.setShipping(value));
  };

  const setRolesContext = (value) => {
    dispatch(actions.setRoles(value));
  }

  const getRolesContext = async (data) => {
    try {
      const result = await getRoles(data);
      if (result) {
        if (data.pageNumber === 1) {
          dispatch(actions.setRoles(result.roles));
        } else {
          dispatch(actions.updateRoles(result.roles));
        }
        dispatch(actions.setTotalRoles(result.total));
      }
    } catch (e) {
      console.error(e);
    }
  };
  const createRoleContext = async (data) => {
    try {
      const result = await createRole(data);
      if (result) {
        getRolesContext({ pageNumber: 1 });
        dispatch(toasterActions.updateToaster({ type: 'success', message: 'New role created' }));
      }
    } catch (e) {
      dispatch(toasterActions.updateToaster({ type: 'error', message: 'Something went wrong' }));
      console.error(e);
    }
  };
  const editRoleContext = async ({ data, id }) => {
    try {
      const result = await editRole({ data, id });
      if (result) {
        getRolesContext({ pageNumber: 1 });
        dispatch(toasterActions.updateToaster({ type: 'success', message: 'Role edited' }));
      }
    } catch (e) {
      dispatch(toasterActions.updateToaster({ type: 'error', message: 'Something went wrong' }));
      console.error(e);
    }
  };

  const getPermissionsContext = async () => {
    try {
      const result = await getPermissions();
      if (result) {
        dispatch(actions.setPermissions(result.permissions));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getPaymentMethodsContext = async () => {
    try {
      const result = await getPaymentMethods();
      console.log(result)
      if (result) {
        dispatch(actions.setPaymentMethods(result.paymentMethods));
        dispatch(actions.setPaymentMethodTypes(result.paymentMethodTypes));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const createPaymentMethodContext = async (data, paymentMethods) => {
    try {
      const result = await createPaymentMethod(data);
      if (result) {
        dispatch(
          toasterActions.updateToaster({ type: 'success', message: 'Payment Method created' })
        );
        let helper = [...paymentMethods];
        if (helper.length === 0) {
          getPaymentMethodsContext();
        } else {
          helper.push(result);
          dispatch(actions.setPaymentMethods(helper));
        }
      }
    } catch (e) {
      dispatch(toasterActions.updateToaster({ type: 'error', message: 'Something went wrong' }));
      console.error(e);
    }
  };

  const editPaymentMethodContext = async ({ data, id, paymentMethods }) => {
    try {
      const result = await editPaymentMethod({ data, id });
      if (result) {
        let helper = [...paymentMethods];
        helper.forEach((item, index) => {
          if (item._id === id) {
            helper[index] = result;
          }
        });
        dispatch(
          toasterActions.updateToaster({ type: 'success', message: 'Payment Method edited' })
        );
        dispatch(actions.setPaymentMethods(helper));
      }
    } catch (e) {
      dispatch(toasterActions.updateToaster({ type: 'error', message: 'Something went wrong' }));
      console.error(e);
    }
  };

  const editPaymentMethodTypeContext = async ({ data, id, paymentMethodTypes }) => {
    try {
      const result = await editPaymentMethodType({ data, id });
      if (result) {
        let helper = [...paymentMethodTypes];
        helper.forEach((item, index) => {
          if (item._id === id) {
            helper[index].is_active = data.is_active;
          }
        });
        dispatch(
          toasterActions.updateToaster({ type: 'success', message: 'Payment Method edited' })
        );
        dispatch(actions.setPaymentMethodTypes(helper));
      }
    } catch (e) {
      dispatch(toasterActions.updateToaster({ type: 'error', message: 'Something went wrong' }));
      console.error(e);
    }
  };

  const getCountriesContext = async () => {
    try {
      const result = await getCountries();
      if (result) {
        dispatch(actions.setCountries(result.countries));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getLocationContext = async () => {
    try {
      const result = await getLocations();
      if (result) {
        dispatch(actions.setLocations(result.locations));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const createLocationContext = async (data, locations) => {
    try {
      const result = await createLocation(data);
      if (result) {
        let helper = [...locations];
        helper.push(result);
        dispatch(actions.setLocations(helper));
        dispatch(
          toasterActions.updateToaster({ type: 'success', message: 'New location created' })
        );
      }
    } catch (e) {
      dispatch(toasterActions.updateToaster({ type: 'error', message: 'Something went wrong' }));
      console.error(e);
    }
  };

  const editLocationContext = async ({ data, id, locations }) => {
    try {
      const result = await editLocation({ data, id });
      if (result) {
        let helper = [...locations];
        helper.map((item, index) => {
          if (item._id === id) {
            helper[index] = result;
          }
        });
        dispatch(toasterActions.updateToaster({ type: 'success', message: 'Location edited' }));
        dispatch(actions.setLocations(helper));
      }
    } catch (e) {
      dispatch(toasterActions.updateToaster({ type: 'error', message: 'Something went wrong' }));
      console.error(e);
    }
  };

  const getShippingContext = async () => {
    try {
      const result = await getShipping();
      if (result) {
        dispatch(actions.setShipping(result.areaZones));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const createShippingContext = async (data, shippings) => {
    try {
      const result = await createShipping(data);
      if (result) {
        let helper = [...shippings];
        helper.push(result);
        dispatch(
          toasterActions.updateToaster({ type: 'success', message: 'Shiping zone created' })
        );
        dispatch(actions.setShipping(helper));
      }
    } catch (e) {
      dispatch(toasterActions.updateToaster({ type: 'error', message: 'Something went wrong' }));
      console.error(e);
    }
  };

  const editShippingContext = async ({ data, id, shippings }) => {
    try {
      const result = await editShipping({ data, id });
      if (result) {
        let helper = [...shippings];
        helper.map((item, index) => {
          if (item._id === id) {
            helper[index] = result;
          }
        });
        dispatch(toasterActions.updateToaster({ type: 'success', message: 'Shiping zone edited' }));
        dispatch(actions.setShipping(helper));
      }
    } catch (e) {
      dispatch(toasterActions.updateToaster({ type: 'error', message: 'Something went wrong' }));
      console.error(e);
    }
  };

  const getTaxContext = async () => {
    try {
      const result = await getTaxes();
      if (result) {
        dispatch(actions.setTaxes(result.taxes));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const createTaxContext = async (data, taxes) => {
    try {
      const result = await createTax(data);
      if (result) {
        let helper = [...taxes];
        helper.push(result);
        dispatch(toasterActions.updateToaster({ type: 'success', message: 'Tax zone created' }));
        dispatch(actions.setTaxes(helper));
      }
    } catch (e) {
      dispatch(toasterActions.updateToaster({ type: 'error', message: 'Something went wrong' }));
      console.error(e);
    }
  };

  const editTaxContext = async ({ data, id, taxes }) => {
    try {
      const result = await editTax({ data, id });
      if (result) {
        let helper = [...taxes];
        helper.map((item, index) => {
          if (item._id === id) {
            helper[index] = result;
          }
        });
        dispatch(toasterActions.updateToaster({ type: 'success', message: 'Tax zone edited' }));
        dispatch(actions.setTaxes(helper));
      }
    } catch (e) {
      dispatch(toasterActions.updateToaster({ type: 'error', message: 'Something went wrong' }));
      console.error(e);
    }
  };

  return {
    getRolesContext,
    createRoleContext,
    editRoleContext,
    getPermissionsContext,
    setPaymentMethodsContext,
    setLocationsContext,
    setTaxesContext,
    setShippingContext,
    setRolesContext,
    getPaymentMethodsContext,
    createPaymentMethodContext,
    editPaymentMethodContext,
    editPaymentMethodTypeContext,
    getCountriesContext,
    getLocationContext,
    createLocationContext,
    editLocationContext,
    getShippingContext,
    createShippingContext,
    editShippingContext,
    getTaxContext,
    createTaxContext,
    editTaxContext,
  };
};

export default settingPerformances;
