import React, { useContext, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import MainContext from '../../MainContext';
import CancelButtonSVG from 'asset/cancel-button.svg';
import { Center } from '@react-three/drei';
import Button from 'components/button';
import { Link } from 'react-router-dom';

export const CancelButtonIcon = styled.img.attrs({
  src: CancelButtonSVG,
})`
  width: 1.197vw;
  height: 1.197vw;
  cursor: pointer;
  // position: absolute;
  position: relative;
  // top: calc(50%);
  // right: 2.135vw;
  left: 1vw;
  // transform: translate(0, -50%);
  -webkit-transition: 200ms linear;
  -ms-transition: 200ms linear;
  transition: 200ms linear;
`;

const Wrapper = styled.div`
  position: relative;
  width: 25.52vw;
  // height: 6.51vw; is changed to having  a bigger one for upgrade info
  height: ${(props) => (props.isUpgrade === 'upgrade' ? '13.21vw' : '6.51vw')};
  border-radius: 2.395vw;
  margin-bottom: 0.781vw;
  // background-color: ${(props) => props.backgroundColor + '99'}; is changed to having  a 0 opacity for upgrade info
  background-color: ${(props) => (props.isUpgrade === 'upgrade' ? props.backgroundColor : props.backgroundColor + '99')};
  // display: flex;
  // align-items: center;
  box-sizing: border-box;
  font-size: 1.25vw;
  color: white;
  overflow: hidden;
  padding-top:${(props) => (props.isUpgrade === 'upgrade' ? '1.2vw' : '1.5vw')};
`;

const TimerBack = styled.div`
  position: absolute;
  width: ${(props) => 100 - props.timer * 20 + '%'};
  height: 12.51vw; 
  background-color: ${(props) => props.backgroundColor + '99'};
`;

const UpgradeHolder = styled.div`
  position:relative;
  margin-top: 2.5vw;
  display: ${(props) => (props.isUpgrade === 'upgrade' ? 'flex' : 'none')};
  float: right;
  right:2vw;
  width: 13.5vw;
  justify-content: space-between;
`;

const MainHolder = styled.div`
  position:relative;
  align-items: center;
  display: flex;
`;

const ToasterComponent = (props) => {
  const { toasterMessages, setToasterContext } = useContext(MainContext).toasters;
  const [timer, setTimer] = useState(5);
  const [mouseHover, setMouseHover] = useState(false);
  const mountedRef = useRef(false);

  useEffect(() => {
    if (mouseHover) {
      mountedRef.current = true;
    } else {
      mountedRef.current = false;
      setTimerFunc(5);
    }
  }, [mouseHover]);
  useEffect(() => {
    if (timer <= 0) {
      onDeleteClick();
    }
  }, [timer]);
  const setTimerFunc = (val) => {
    if (props.isUpgrade === 'upgrade') return null;  // is added to having no timer when the message is about the upgrade
    if (mountedRef.current) return null;
    setTimer(val - 0.1);
    if (val > 0) {
      setTimeout(() => {
        setTimerFunc(val - 0.1);
      }, 50);
    }
  };
  const onDeleteClick = () => {
    let helper = [...toasterMessages];
    helper.splice(props.toastIndex, 1);
    setToasterContext(helper);
  };

  return (
    <Wrapper
      backgroundColor={props.backgroundColor}
      onMouseEnter={() => setMouseHover(true)}
      onMouseLeave={() => setMouseHover(false)}
      isUpgrade={props.isUpgrade}
    >
      <MainHolder>
        <TimerBack timer={timer} backgroundColor={props.backgroundColor}/>
        {props.children}
        <CancelButtonIcon onClick={onDeleteClick} />
      </MainHolder>
      <UpgradeHolder isUpgrade={props.isUpgrade}>
        <Button
          onClick={onDeleteClick}
          marginTop={0}
          text="Cancel"
          size="medium"
          type="secondary"
        />
        <Link style={{ textDecoration: 'none' }} to="/plans">
          <Button
            onClick={onDeleteClick}
            marginTop={0}
            text="UPGRADE"
            size="medium"
            image={true}
          />
        </Link>
      </UpgradeHolder>
    </Wrapper>
  );
};

export default ToasterComponent;
