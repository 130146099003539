import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext } from 'context/themeContext';
import MainContext from 'MainContext';
import LogoutSVG from 'asset/logout.svg';
import MoonSVG from 'asset/moon.svg';
import Switch from 'components/switch';
import { Space } from 'components/wrapper';
import axios from 'axios';
import { updateUserProfile } from 'containers/login/container/api';

const LogoutImage = styled.img.attrs({
  src: LogoutSVG,
})`
  width: 1.145vw;
  height: 1.145vw;
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
`;

const MoonImage = styled.img.attrs({
  src: MoonSVG,
})`
  width: 1.327vw;
  height: 1.327vw;
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
`;

const Wrapper = styled.div`
  position: relative;
  border-radius: 3.487vw;
  height: 3.487vw;
  width: calc(100% - 0.52vw);
  margin-top: 0.26vw;
  margin-left: 0.26vw;
  font-size: 0.728vw;
  font-weight: normal;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.textSecondary};
  transition: 200ms linear;
  &:hover {
    background-color: ${(props) => props.theme.pure};
    cursor: pointer;
  }
`;

const Circle = styled.div`
  width: 2.08vw;
  height: 2.08vw;
  margin-right: 0.624vw;
  margin-left: 1.04vw;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 300ms linear;
  background-color: ${(props) => props.theme.borderBackground};
`;

const App = (props) => {
  const { darkThemeSelected, theme, setDarkThemeSelected } = useContext(ThemeContext);
  const { profile, setTokenContext,setSelectedRoleContext ,setProfile,setFamilySize } = useContext(MainContext).login;
  const { setShowDrawer } = props;

  const onLogoutClick = () => {
    localStorage.removeItem('token');
    axios.defaults.headers.common.Authorization = '';
    axios.defaults.headers.common.family = '';
    setTokenContext('');
    setSelectedRoleContext({});
    setShowDrawer(false);
    setProfile({})
    setFamilySize({})
  };

  const onToggleClick = () => {
    let darkThemeSelectedHelper= !darkThemeSelected;
    updateUserProfile({ user_light_theme:!darkThemeSelectedHelper });
    setDarkThemeSelected(darkThemeSelectedHelper);
  };

  return (
    <>
      <Wrapper theme={theme} onClick={onToggleClick}>
        <Circle theme={theme}>
          <MoonImage />
        </Circle>
        Dark Mode
        <Space width={2} />
        <Switch active={darkThemeSelected} toggle={() => console.log()} />
      </Wrapper>
      <Wrapper theme={theme} onClick={onLogoutClick}>
        <Circle theme={theme}>
          <LogoutImage />
        </Circle>
        Logout
      </Wrapper>
    </>
  );
};

export default App;
