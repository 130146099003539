export const types = {
  GET_HUB: 'GET_HUB',
  GET_HUBS: 'GET_HUBS',
  UPDATE_HUBS: 'UPDATE_HUBS',
  SET_TOTAL_HUBS: 'SET_TOTAL_HUBS',
  HUBS_GET_MATERIALS: 'HUBS_GET_MATERIALS',
};

const actions = {
  setHub: (payload) => ({
    type: types.GET_HUB,
    payload,
  }),
  setHubs: (payload) => ({
    type: types.GET_HUBS,
    payload,
  }),
  updateHubs: (payload) => ({
    type: types.UPDATE_HUBS,
    payload,
  }),
  setTotalHubs: (payload) => ({
    type: types.SET_TOTAL_HUBS,
    payload,
  }),
  setMaterials: (payload) => ({
    type: types.HUBS_GET_MATERIALS,
    payload,
  }),
};

export default actions;
