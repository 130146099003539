import { types } from './actions';

const reducer = (state, action) => {
  switch (action.type) {
    case types.SET_BOARDING_STATE:
      return { ...state, boardingState: action.payload };
    case types.SET_BOARDING_OPEN:
      return { ...state, boardingOpen: action.payload };
    case types.SET_BOARDING_FIRST_LAUNCH:
      return { ...state, boardingFirstLaunch: action.payload };
    default:
      return state;
  }
};

export const key = 'boarding';
export default reducer;
