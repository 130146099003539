export const types = {
  SET_BOARDING_STATE: 'SET_BOARDING_STATE',
  SET_BOARDING_OPEN: 'SET_BOARDING_OPEN',
  SET_BOARDING_FIRST_LAUNCH: 'SET_BOARDING_FIRST_LAUNCH',
};

const actions = {
  setBoardingState: (payload) => ({
    type: types.SET_BOARDING_STATE,
    payload,
  }),
  setBoardingOpen: (payload) => ({
    type: types.SET_BOARDING_OPEN,
    payload,
  }),
  setBoardingFirstLaunch: (payload) => ({
    type: types.SET_BOARDING_FIRST_LAUNCH,
    payload,
  }),
};

export default actions;
