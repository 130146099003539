export const types = {
  SET_BASKET_ORDERS: 'SET_BASKET_ORDERS',
  SET_CHECKOUT_INVOICE: 'SET_CHECKOUT_INVOICE',
  SET_MATERIALS: 'SET_MATERIALS',
  SET_LOADING_CREATE_ORDER: 'SET_LOADING_CREATE_ORDER',
};

const actions = {
  setBasketOrders: (payload) => ({
    type: types.SET_BASKET_ORDERS,
    payload,
  }),
  setCheckoutInvoice: (payload) => ({
    type: types.SET_CHECKOUT_INVOICE,
    payload,
  }),
  setMaterials: (payload) => ({
    type: types.SET_MATERIALS,
    payload,
  }),
  setLoadingCreateOrder: (payload) => ({
    type: types.SET_LOADING_CREATE_ORDER,
    payload,
  }),
};

export default actions;
