export const types = {
  SET_INVOICES: 'SET_INVOICES',
  UPDATE_INVOICES: 'UPDATE_INVOICES',
  TOTAL_INVOICES: 'TOTAL_INVOICES',
  ORDER_MODAL_STATE: 'ORDER_MODAL_STATE',
  ORDER_HUBS: 'ORDER_HUBS',
  SET_ATTACH_FILES:'SET_ATTACH_FILES'
};

const actions = {
  setInvoices: (payload) => ({
    type: types.SET_INVOICES,
    payload,
  }),
  updateInvoices: (payload) => ({
    type: types.UPDATE_INVOICES,
    payload,
  }),
  setTotalInvoices: (payload) => ({
    type: types.TOTAL_INVOICES,
    payload,
  }),
  setOrderModalState: (payload) => ({
    type: types.ORDER_MODAL_STATE,
    payload,
  }),
  setOrderHubs: (payload) => ({
    type: types.ORDER_HUBS,
    payload,
  }),
  setAttachFiles:(payload) => ({
    type: types.SET_ATTACH_FILES,
    payload,
  }),
};

export default actions;
