export const types = {
  SET_PERMISSIONS: 'SET_PERMISSIONS',
  SETTING_SET_ROLES: 'SETTING_SET_ROLES',
  UPDATE_ROLES: 'UPDATE_ROLES',
  TOTAL_ROLES: 'TOTAL_ROLES',
  SET_PAYMENT_METHODS: 'SET_PAYMENT_METHODS',
  SET_PAYMENT_METHOD_TYPES: 'SET_PAYMENT_METHOD_TYPES',
  SET_COUNTRIES: 'SET_COUNTRIES',
  SET_LOCATIONS: 'SET_LOCATIONS',
  SET_SHIPPING: 'SET_SHIPPING',
  SET_TAXES: 'SET_TAXES',
};

const actions = {
  setPermissions: (payload) => ({
    type: types.SET_PERMISSIONS,
    payload,
  }),
  setRoles: (payload) => ({
    type: types.SETTING_SET_ROLES,
    payload,
  }),
  updateRoles: (payload) => ({
    type: types.UPDATE_ROLES,
    payload,
  }),
  setTotalRoles: (payload) => ({
    type: types.TOTAL_ROLES,
    payload,
  }),
  setPaymentMethods: (payload) => ({
    type: types.SET_PAYMENT_METHODS,
    payload,
  }),
  setPaymentMethodTypes: (payload) => ({
    type: types.SET_PAYMENT_METHOD_TYPES,
    payload,
  }),
  setCountries: (payload) => ({
    type: types.SET_COUNTRIES,
    payload,
  }),
  setLocations: (payload) => ({
    type: types.SET_LOCATIONS,
    payload,
  }),
  setShipping: (payload) => ({
    type: types.SET_SHIPPING,
    payload,
  }),
  setTaxes: (payload) => ({
    type: types.SET_TAXES,
    payload,
  }),
};

export default actions;
