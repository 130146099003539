import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext } from 'context/themeContext';

const Wrapper = styled.div`
  direction:ltr;
  position: relative;
  height: 1.77vw;
  width: 3.279vw;
  background-color: ${(props) =>
    props.active ? props.theme.blueColorSecondary : props.theme.inputBackground};
  -webkit-transition: 200ms linear;
  -ms-transition: 200ms linear;
  transition: 200ms linear;
  border-radius: 1.77vw;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: calc((1.77vw - 1.405vw) / 2);
  padding-left: ${(props) =>
    props.active
      ? 'calc((3.279vw - 1.405vw) - ((1.77vw - 1.405vw) / 2))'
      : 'calc((1.77vw - 1.405vw) / 2)'};
  &:hover {
    cursor: pointer;
    background-color: ${(props) =>
      props.active ? props.theme.blueColorSecondaryHover : props.theme.inputBackground};
  }
`;

const Circle = styled.div`
  position: relative;
  height: 1.405vw;
  width: 1.405vw;
  background-color: white;
  border-radius: 50%;
  box-sizing: border-box;
`;

const Checkbox = (props) => {
  const { theme } = useContext(ThemeContext);
  const { toggle, active } = props;
  return (
    <Wrapper theme={theme} active={active} onClick={() => toggle(!active)}>
      <Circle active={active} />
    </Wrapper>
  );
};

export default Checkbox;
