import React, { useContext, useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import axios from 'axios';
import MainContext from 'MainContext';
import { ThemeContext } from 'context/themeContext';
import io from 'socket.io-client';
import { getFamilyBySubdomain,getCurrencies } from 'containers/login/container/api';
import { setCurrencies } from 'utils/currencies';
import { sleep } from 'utils';
//const jwtDecode = require('jwt-decode')
import jwt_decode from 'jwt-decode';
const AuthRequired = (props) => {
  const {
    token: contextToken,
    socket,
    setSocketContext,
    notifications,
    getNotificationsContext,
    getHubNotificationsContext,
    newNotificationReceived,
    
    setTokenContext,
    profile,
    getUserProfileContext,
    getUserUploadSizeContext,
    setRolesContext,
    selectedRole,
    setFamilyCurrency,
    setFamilyType,
    roles: contextRoles,
    setSelectedHUbModeId,
    setSelectedHUbModeType,
    selectedHUbModeId,
  } = useContext(MainContext).login;
  const { theme, toggleTheme, darkThemeSelected,setDarkThemeSelected } = useContext(ThemeContext);

  let token = localStorage.getItem('token');
  
  //read token from url if present (used in generating pdf reports)
  const urlParams=new  URLSearchParams(window.location.search);
  if(urlParams.has('token')){
    token= urlParams.get('token');
    // url token is access token (not refresh so we set it as Bearer)
    axios.defaults.headers.common.Authorization = 'Bearer ' + token;
    
  }


  
  const setupSocket = () => {
    if (token && !socket) {
      const newSocket = io(process.env.REACT_APP_CHAT_SERVICE_URL, {
        query: {
          token: localStorage.getItem('token'),
        },
      });

      newSocket.on('disconnect', () => {
        setSocketContext(null);
      });

      newSocket.on('connect', () => {
        let decoded = jwt_decode(token);
        newSocket.emit('joinRoom', {
          chatroomId: decoded.id,
        });
        setSocketContext(newSocket);
      });
      newSocket.on('newNotification', (notification) => {
        newNotificationReceived(notification);
      });

    }
  };

  

  useEffect(() => {
    setupSocket();
    // getNotificationsContext()
  }, [token]);

  useEffect(() => {
    if (axios.defaults.headers.common.family) {
      if (!selectedHUbModeId) {
        getNotificationsContext();
      } else {
        getHubNotificationsContext(selectedHUbModeId);
      }
    }
  }, [axios.defaults.headers.common.family, selectedHUbModeId]);

  let authorized = false;
  if (token && token.length > 0) {
    authorized = true;
    // axios.defaults.headers.common.Authorization = 'Bearer ' + token
    let decoded = jwt_decode(token);
    if (!axios.defaults.headers.common.family) {
      // axios.defaults.headers.common.family = (!profile.defualt_family || profile.defualt_family === 'self') ? '' : profile.defualt_family
      axios.defaults.headers.common.family = decoded.cellphone
    }
    if (contextToken.length === 0) {
      setTokenContext(token);
    }
  } else {
    if (contextToken.length !== 0) {
      setTokenContext('');
    }
  }
  useEffect(async() => {
    getUserProfileContext();
    getUserUploadSizeContext();
    let currenciesList=await getCurrencies();
    setCurrencies(currenciesList);
  }, [axios.defaults.headers.common.family]);
  useEffect(() => {
    const { Authorization } = axios.defaults.headers.common;
    if (Authorization && Authorization.length > 0) {
      const AuthorizationSplit = Authorization.split(' ');
      const decoded = jwt_decode(AuthorizationSplit[1]);
      const roles = JSON.parse(decoded.roles);
      setRolesContext(roles);
    }
  }, [axios.defaults.headers.common.Authorization]);

  useEffect(() => {
      if(typeof profile.user_light_theme == 'boolean')
        setDarkThemeSelected(!profile.user_light_theme);
      
  }, [profile]);
  

  useEffect(async () => {
    if(typeof profile.defualt_family == 'string'){
        while(typeof window.clarity != 'function')
          await sleep(1000);
        window.clarity("set", "side", "admin");
        let family=profile.defualt_family=='self'? profile.family : profile.defualt_family;
        window.clarity("set", "userId", `${family}:${profile.cellphone}:${profile._id}`);
        window.clarity("set", "userIdShort", profile._id);
        window.clarity("set", "userCellphone", profile.cellphone);
        window.clarity("set", "family", family);
    }
  }, [profile.defualt_family]);

  useEffect(() => {
    if (selectedRole) {
      getFamilyBySubdomainFunc();
      contextRoles.map((role) => {
        if (role.family === selectedRole) {
          if (role.hubId) {
            setSelectedHUbModeId(role.hubId);
            setSelectedHUbModeType(role.hubType);
          } else {
            setSelectedHUbModeId('');
            setSelectedHUbModeType('');
          }
        }
      });
    } else {
      setSelectedHUbModeId('');
      setSelectedHUbModeType('');
    }
  }, [selectedRole]);

  const getFamilyBySubdomainFunc = async () => {
    const respone = await getFamilyBySubdomain(selectedRole);
    setFamilyCurrency(respone.currency);
    setFamilyType(respone.admin_type);
  };
  return authorized === true ? (
    profile.first_name ? (
      props.children
    ) : null
  ) : (
    <Redirect to={props.redirect} />
  );
};

export default AuthRequired;
